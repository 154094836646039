import {
    title,
    main,
    whiteColor,
    mainRaised,
  } from "assets/jss/material-kit-pro-react.js";
  
  const galleryPageStyle = {
    container: {
      color: whiteColor,
      paddingRight: "15px",
      paddingLeft: "15px",
      marginRight: "auto",
      marginLeft: "auto",
      width: "100%",
      zIndex: "2"
      // "@media (min-width: 400px)": {
      //   maxWidth: "1440px"
      // },
      // "@media (min-width: 768px)": {
      //   maxWidth: "720px"
      // },
      // "@media (min-width: 992px)": {
      //   maxWidth: "960px"
      // },
      // "@media (min-width: 1200px)": {
      //   maxWidth: "1140px"
      // },
      // "@media (min-width: 1292px)": {
      //   maxWidth: "2560px"
      // },
    },
    title: {
      ...title,
      textAlign: "center",
      display: "inline-block",
      position: "relative",
      marginTop: "30px",
      minHeight: "32px",
      color: "whiteColor",
      textShadow: " 1px 1px 0 black",
      textDecoration: "none"
    },
    subTitle: {
      ...title,
      textAlign: "center",
      display: "inline-block",
      position: "relative",
      marginTop: "30px",
      minHeight: "32px",
      color: "whiteColor",
      textShadow: " 1px 1px 0 black",
      textDecoration: "underline"
    },
    subtitle: {
      fontSize: "1.313rem",
      maxWidth: "500px",
      margin: "10px auto 0"
    },
    main: {
      ...main
    },
    mainRaised: {
      ...mainRaised
    },
    block: {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block"
    },
    inlineBlock: {
      display: "inline-block",
      padding: "0px",
      width: "auto"
    },
    list: {
      marginBottom: "0",
      padding: "0",
      marginTop: "0"
    },
    left: {
      float: "left!important",
      display: "block"
    },
    right: {
      padding: "15px 0",
      margin: "0",
      float: "right"
    },
    icon: {
      width: "18px",
      height: "18px",
      top: "3px",
      position: "relative"
    },
    change: {
      borderRadius: "3px"
    },
    modal: {
      padding: "20rem"
    },
    image: {
      position: 'relative',
      height: 300,
      // [theme.breakpoints.down('xs')]: {
      //   width: '100% !important', // Overrides inline-style
      //   height: 100,
      // },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.15,
        },
        '& $imageMarked': {
          opacity: 0,
        },
        '& $imageTitle': {
          border: '4px solid currentColor',
        },
      },
    },
  };
  
  export default galleryPageStyle;
  