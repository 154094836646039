import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Colors
import {
  hexToRgb,
  blackColor
} from "assets/jss/material-kit-pro-react.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import ButtonBase from '@material-ui/core/ButtonBase';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
//Images
import LogoImage from "assets/img/logoDesign/Herbanbakerroundpt2.webp";
import SFImage from "assets/img/specialtyFinishes/Painted_stacked_stone_w_painted_shadow_on_smooth_board_2009.webp";
import SignImage from "assets/img/signs/ChemistAntidoteLounge/ChemistFront.webp";
import MuralImage from "assets/img/brushcanImg/Panorama1.jpg";
import GDImage from "assets/img/graphicDesign/OrangePeeltshirtAshevilleNC2009.webp";
import CAImage from "assets/img/conceptArt/HotelLobbyMural/color_rendering_for_hotel_lobby_map_mural_2016.webp"
import IllustrationImage from "assets/img/illustration/ParkinglottramDollywoodPigeonForgeTN2006Gouacheonvintagenotecard.webp"

const images = [
  {
    url: SFImage,
    title: 'Specialty Finishes',
    width: '33.333333333%',
    path: "/specialty-finishes",
  },
  {
    url: LogoImage,
    title: 'Logo Design',
    width: '33.333333333%',
    path: "logo-design"  },
  {
    url: SignImage,
    title: 'Signs',
    width: '33.333333333%',
    path: "signs"
  },
  {
    url: MuralImage,
    title: 'Murals',
    width: '99.99%',
    path: "murals"
  },
  {
    url: GDImage,
    title: 'Graphic Design',
    width: '33.333333333%',
    path: "graphic-design"
  },
  {
    url: CAImage,
    title: 'Concept Art',
    width: '33.333333333%',
    path:"/concept-art",
  },
  {
    url: IllustrationImage,
    title: 'Illustration',
    width: '33.333333333%',
    path: "/illustration",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        backgroundColor: "rgba(60,72,88,1)",
        // backgroundColor: "rgba(156,192,185,0.9)",
        border: '4px solid rgba(156,192,185,1)',
        borderRadius: "4px"
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "rgba(247,246,244,1)"
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    backgroundColor: "rgba(60,72,88,0.8)",
    borderRadius: "4px"
    // opacity: "0.5"
  },
  containerStyle: {
    padding: "1rem",
    width: "auto",
  },
  mainRaised: {
    "@media (max-width: 576px)": {
      // marginTop: "-30px",
      // marginLeft: "2em",
      // paddingRight: "2em",
    },
    "@media (max-width: 830px)": {
      // marginLeft: "2px",
      // marginRight: "2px"
    },
    // margin: "-60px 30px 0px",
    marginLeft: "1em",
    marginRight: "1em",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)"
  },
}));


export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div>
      <div>
        <GridContainer className={classes.containerStyle, classes.mainRaised}>
          <div className={classes.root} style={{padding: "1em"}}>
            {images.map((image) => (
              <Link
                key={image.title}
                className={classes.image}
                style={{
                  width: image.width,
                  // backgroundColor: "black",
                }}
                to={image.path}
                // disableRipple={true}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    // borderStyle: "solid",
                    // borderWidth: "5px",
                    // borderColor: "rgba(255,255,255,0.5)",
                    backgroundImage: `url(${image.url})`,
                    borderRadius: "3px",
                    margin: "5px"
                  }}
                />
                <span className={classes.imageButton}>
                  <Typography
                    // component="span"
                    variant="h6"
                    className={classes.imageTitle}
                    style={{ textAlign: "center" }}
                  >
                    {image.title}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </Link>
            ))}
          </div>
        </GridContainer>
      </div>
    </div>
  );
}
