/*eslint-disable*/ 
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
//ICons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

// Gallerys
import {
  ConceptArtGalleryFrontImgs, 
  AlbumCoverGallery, 
  BBQMenuGallery,
  CarnivalBBQ1Gallery,
  CarnivalBBQ2Gallery,
  ToyStoreGallery,
  VintagePosterGallery,
  BreweryMuralGallery,
  WarehouseStudioGallery,
  DwntChildrensGallery,
  ExteriorGhostGallery,
  HunterBanksGallery,
  GardenStoreTruckGallery,
  BakerySignGallery,
  VIntageDeliverTruckGallery,
  BrushCaseGallery,
  OfficeBuildingMuralGallery,
  ResturantMenuGallery,
  HotelLobbyMuralGallery,
  DistilleryMural1Gallery,
  DistilleryMural2Gallery,
  PosterArtRetrospectiveGallery,
  ItalianRestMuralGallery,
  LibraryMuralGallery,
  BBQMuralGallery,
  MuseumDiorama1Gallery,
  MuseumDiorama2Gallery,
  MuseumDiorama3Gallery,
  MuseumMuralGallery,
  ScrollSignBorderGallery,
  SpanishResturantMuralGallery,
  TacoStandMenuGallery,
  UnderwaterChildrensMeseumGallery,
  YMCAMuralGallery,
  BreweryTankMuralGallery,
  BrushcanLogoConceptGallery,
  ChildrensHospitalMuralGallery,
  ChildrensMuseumMuralMuralGallery,
  HerbalCompanyGallery,
  HotelLobbyGallery,
  MexicanResturantGallery,
  OutdoorOufittersGallery
} from "../ConceptArt/CAImages.js";
// const {Gallery0, Gallery1} = lazy(() => import("../ConceptArt/CAImages.js"));

import galleryPageStyle from "assets/jss/material-kit-pro-react/views/galleryPageStyle.js";

// Sections for this page
import SectionGallery from "../Sections/SectionGallery.js";

const useStyles = makeStyles(galleryPageStyle);

export default function ConceptArtPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  // const testRef = useRef(null);
  // let testRefSend = null;

  const frontGallery = [...ConceptArtGalleryFrontImgs];
  const galleryArray = [
    AlbumCoverGallery, 
    BBQMenuGallery,
    CarnivalBBQ1Gallery,
    CarnivalBBQ2Gallery,
    ToyStoreGallery,
    VintagePosterGallery,
    BreweryMuralGallery,
    WarehouseStudioGallery,
    DwntChildrensGallery,
    ExteriorGhostGallery,
    HunterBanksGallery,
    GardenStoreTruckGallery,
    BakerySignGallery,
    VIntageDeliverTruckGallery,
    BrushCaseGallery,
    OfficeBuildingMuralGallery,
    ResturantMenuGallery,
    HotelLobbyMuralGallery,
    DistilleryMural1Gallery,
    DistilleryMural2Gallery,
    PosterArtRetrospectiveGallery,
    ItalianRestMuralGallery,
    LibraryMuralGallery,
    BBQMuralGallery,
    MuseumDiorama1Gallery,
    MuseumDiorama2Gallery,
    MuseumDiorama3Gallery,
    MuseumMuralGallery,
    ScrollSignBorderGallery,
    SpanishResturantMuralGallery,
    TacoStandMenuGallery,
    UnderwaterChildrensMeseumGallery,
    YMCAMuralGallery,
    BreweryTankMuralGallery,
    BrushcanLogoConceptGallery,
    ChildrensHospitalMuralGallery,
    ChildrensMuseumMuralMuralGallery,
    HerbalCompanyGallery,
    HotelLobbyGallery,
    MexicanResturantGallery,
    OutdoorOufittersGallery
  ]
  const classes = useStyles();
  // console.log(testRef)

    // const x = container.offsetwidth;
    // console.log(x);
  // const parentWidth = () => {this.clientWidth};

  // useEffect(() => {
  //   // console.log('width', testRef.current ? testRef.current.offsetWidth : 0);
  //   console.log(testRef.current.offsetWidth);
  //   testRefSend = testRef.current.offsetWidth;
  // }, [testRef.current]);

  return (
    <div>
      <Header
        color="info"
        brand="Brushcan"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        {...rest}
      />
      <Parallax image={require("assets/img/conceptArt/HotelLobbyMural/color_rendering_for_hotel_lobby_map_mural_2016.webp")} filter="dark">
        <div className={classes.container}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{textAlign: "center", color: "#f7f6f4"}}>
                <h1 style={ { fontWeight: "bold" }}>
                Concept Art
                </h1>
                <br />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{textAlign: "center"}}>
                <p style={{ fontSize: "large", color: "#f7f6f4" }}>
                <u>The energetic beginnings of most of the jobs seen throughout the site</u>
                </p>
                <br />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        
            {/* Show sections */}
            <SectionGallery 
              frontGallery={frontGallery} 
              galleryArray={galleryArray}
            />

        </div>
      </div>
      <Footer
        content={
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <div>
                  <div>
                    <a href="https://www.facebook.com/brushcan.copm/" target="_blank">
                    <FontAwesomeIcon icon={faFacebookSquare} style={{marginTop: ".3em", color: "#4267B2"}} size="2x"/> Facebook
                    </a>
                    <a href="https://www.instagram.com/brushcan" target="_blank">
                    <FontAwesomeIcon icon={faInstagramSquare} style={{marginLeft: "2em", marginTop: ".3em", color: "#C13584"}} size="2x"/> Instagram
                    </a>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <div style={{ marginTop: "1.2em", color: "black"}}>
                  &copy; {1900 + new Date().getYear()} {" "}
                  <a
                    href="https://www.overpassdev.com"
                    target="_blank"
                  >
                    Overpass Software Development
              </a>{" "}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        }
      />
    </div>
  );
}
