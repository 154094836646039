import React from "react";
// @material-ui/core components
import { bcTextColor } from "assets/jss/material-kit-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// Media
import Scott from "assets/img/faces/scottpic2.webp";
// styles
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";

const useStyles = makeStyles(profilePageStyle);

export default function SectionProduct() {

  const classesPro = useStyles(profilePageStyle);
  const imageClasses = classNames(
    classesPro.imgRaised,
    classesPro.imgRoundedCircle,
    classesPro.imgFluid
  );
  return (
    <div className={classNames(
      classesPro.main,
      classesPro.mainRaised,
      classesPro.section)}
      style={{ margin: "4rem 0rem 4rem", padding: "1.5rem", width: "80%", marginLeft: "auto", marginRight: "auto" }}
    >
      <div className={classesPro.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classesPro.profile}>
              <div style={{ width: '100%', margin: 'auto', paddingRight: "1em", paddingLeft: "1em" }}>
                <img src={Scott} alt="Scott Allred Profile Picture" className={imageClasses} />
                <div style={{ color: "#9cc0b9", borderStyle: "none none solid none", borderColor: "#3C4858" }}>
                  <h2><b>Scott Allred</b></h2>
                </div>
              </div>
              <br/>
              <div >
                <p style={{textAlign: "center", fontSize: "large", color: "#f7f6f4"}}>
                  After receiving a degree in Graphic Design from the Art Academy of Cincinnati, working at Queen City Sign Co
                  then 9 years for Paramount Studios, Scott landed in Asheville and started Allred Design.
                  10 years later, with former business partner Jeremy Russell, transformed that into Brushcan Murals and Sign.{" "} <br/>
                  <b><a href="http://ashvegas.com/asheville-artist-scott-allred-looks-back-at-career-one-sketch-at-a-time/"><u> Full background story here </u></a></b>
                </p>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
