//Real
import AmeliaIsland from "assets/img/specialtyFinishes/Amelia_Island_Museum_of_History_Faux_painted_bricks_on_wooden_box_2004.webp";
import PaintedOak from "assets/img/specialtyFinishes/Painted_oak_wood_grain_on_plastic_panel_2010.webp"
import PaintedStackedStone from "assets/img/specialtyFinishes/Painted_stacked_stone_w_painted_shadow_on_smooth_board_2009.webp"
//Folders
import PaintedRustSteel1 from "assets/img/specialtyFinishes/Drake_Well_Museum/Drake_Well_Museum_Titusville_PA_Faux_painted_rusted_steel_onwooden_box_before_1_2011.webp"
import PaintedRustSteel2 from "assets/img/specialtyFinishes/Drake_Well_Museum/Drake_Well_Museum_Titusville_PA_Faux_painted_rusted_steel_onwooden_box_finished_2_2011.webp"
import PaintedRustSteel3 from "assets/img/specialtyFinishes/Drake_Well_Museum/Drake_Well_Museum_Titusville_PA_Faux_painted_rusted_steel_onwooden_box_detail_3_2011.webp"
//
import PaintedMarbleFinish1 from "assets/img/specialtyFinishes/Museum_of_the_new_South_Charlotte/Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_finished_paint_1.webp"
import PaintedMarbleFinish2 from "assets/img/specialtyFinishes/Museum_of_the_new_South_Charlotte/Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_finished_paint_2.webp"
import PaintedMarbleInstall1 from "assets/img/specialtyFinishes/Museum_of_the_new_South_Charlotte/Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_installed_1.webp"
import PaintedMarbleInstall2 from "assets/img/specialtyFinishes/Museum_of_the_new_South_Charlotte/Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_installed_2.webp"


//Gallery specific front page images
export const SpecialtyFinishesGalleryFrontImgs = [
    {
        imgAlt: "Museum of the new South Charlotte NC Faux painted marble finished paint 1",
        image: PaintedMarbleFinish1,
        thumbnail: PaintedMarbleFinish1,
        width: 200,
        height: 400,
        key: 0,
        title: "Painted Marble",
        // isFeatured: true,
    },
    {
        imgAlt: "Amelia Island Museum of History faux painted bricks on wooden box - 2004",
        image: AmeliaIsland,
        thumbnail: AmeliaIsland,
        width: 200,
        height: 400,
        key: 1,
        title: "Painted Bricks",
        // isFeatured: true,
    },
    {
        imgAlt: "Painted oak wood grain on plastic panel - 2010",
        image: PaintedOak,
        thumbnail: PaintedOak,
        width: 200,
        height: 500,
        key: 2,
        title: "Painted Oak Wood Grain",
    },
    {
        imgAlt: "Painted stacked stone with painted shadow on smooth board - 2009",
        image: PaintedStackedStone,
        thumbnail: PaintedStackedStone,
        width: 200,
        height: 500,
        key: 3,
        title: "Painted Stacked Stone",
        // isFeatured: true,
    },
    {
        imgAlt: "Drake Well Museum Titusville PA faux painted rusted steel on wooden box before 1 - 2011",
        image: PaintedRustSteel3,
        thumbnail: PaintedRustSteel3,
        width: 200,
        height: 240,
        key: 4,
        title: "Painted Rusted Steel",
        isFeatured: true,
    },
];

// Galleries in order

export const PaintedMarbleGallery = [
    {
        key: 0,
        src: PaintedMarbleFinish1,
        thumbnail: PaintedMarbleFinish1,
        width: 1024,
        height: 'auto',
        alt: "Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_finished_paint_1",
        caption: "Museum of the new South Charlotte NC Faux painted marble finished paint 1"
    },
    {
        key: 1,
        src: PaintedMarbleFinish2,
        thumbnail: PaintedMarbleFinish2,
        width: 1024,
        height: 'auto',
        alt: "Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_finished_paint_2",
        caption: "Museum of the new South Charlotte NC Faux painted marble finished paint 2"
    },
    {
        key: 2,
        src: PaintedMarbleInstall1,
        thumbnail: PaintedMarbleInstall1,
        width: 1024,
        height: 'auto',
        alt: "Museum_of_the_new_South_Charlotte_NC_Faux_painted_marble_installed_1",
        caption: "Museum of the new South Charlotte NC Faux painted marble installed 1"
    },
    {
        key: 3,
        src: PaintedMarbleInstall2,
        thumbnail: PaintedMarbleInstall2,
        width: 1024,
        height: 'auto',
        alt: "Museum of the new South Charlotte NC faux painted marble installed 2",
        caption: "Museum of the new South Charlotte NC faux painted marble installed 2"
    },
];
export const AmeliaIslandGallery = [
    {
        key: 0,
        src: AmeliaIsland,
        thumbnail: AmeliaIsland,
        width: 1024,
        height: 'auto',
        alt: 'Amelia_Island_Museum_of_History_Faux_painted_bricks_on_wooden_box_2004',
        caption: "Amelia Island Museum of History faux painted bricks on wooden box - 2004"
    },

];
export const PaintedOakGallery = [
    {
        key: 0,
        src: PaintedOak,
        thumbnail: PaintedOak,
        width: 1024,
        height: 'auto',
        alt: 'Painted_oak_wood_grain_on_plastic_panel_2010',
        caption: "Painted oak wood grain on plastic panel - 2010"
    },
];
export const PaintedStackedStoneGallery = [
    {
        key: 0,
        src: PaintedStackedStone,
        thumbnail: PaintedStackedStone,
        width: 1024,
        height: 'auto',
        alt: 'Painted_stacked_stone_w_painted_shadow_on_smooth_board_2009',
        caption: "Painted stacked stone with painted shadow on smooth board - 2009"
    },
];
export const PaintedRustSteelGallery = [
    {
        key: 0,
        src: PaintedRustSteel1,
        thumbnail: PaintedRustSteel1,
        width: 1024,
        height: 'auto',
        alt: "Drake_Well_Museum_Titusville_PA_Faux_painted_rusted_steel_onwooden_box_before_1_2011",
        caption: "Drake Well Museum Titusville PA faux painted rusted steel on wooden box before 1 - 2011"
    },
    {
        key: 1,
        src: PaintedRustSteel2,
        thumbnail: PaintedRustSteel2,
        width: 1024,
        height: 'auto',
        alt: "Drake_Well_Museum_Titusville_PA_Faux_painted_rusted_steel_onwooden_box_finished_2_2011",
        caption: "Drake Well Museum Titusville PA faux painted rusted steel on wooden box finished 2 - 2011"
    },
    {
        key: 2,
        src: PaintedRustSteel3,
        thumbnail: PaintedRustSteel3,
        width: 1024,
        height: 'auto',
        alt: "Drake_Well_Museum_Titusville_PA_Faux_painted_rusted_steel_onwooden_box_detail_3_2011",
        caption: "Drake Well Museum Titusville PA faux painted rusted steel on wooden box detail 3 - 2011"
    },
];
