//Real
//Folders
//
import AloftMural1 from "assets/img/murals/Aloft mural/Aloftmuralfinishedpanel.webp"
import AloftMural2 from "assets/img/murals/Aloft mural/Aloftmuralfinishedpanel2.webp"
import AloftMural3 from "assets/img/murals/Aloft mural/Aloftmuralinstalleddetail.webp"
import AloftMural4 from "assets/img/murals/Aloft mural/Aloftmuralinstalledfinal.webp"
import AloftMural5 from "assets/img/murals/Aloft mural/AloftmuralinstalledTHUMBNAIL.webp"
import AloftMural6 from "assets/img/murals/Aloft mural/Aloftmuralprogress1.webp"
import AloftMural7 from "assets/img/murals/Aloft mural/Aloftmuralprogress2.webp"
import AloftMural8 from "assets/img/murals/Aloft mural/Aloftmuralprogress3.webp"
import AloftMural9 from "assets/img/murals/Aloft mural/Aloftmuralprogress4.webp"
import AloftMural10 from "assets/img/murals/Aloft mural/Aloftmuralprogress5.webp"
import AloftMural11 from "assets/img/murals/Aloft mural/Aloftmuralprogress6.webp"
import AloftMural12 from "assets/img/murals/Aloft mural/Aloftmuralprogress7.webp"
import AloftMural13 from "assets/img/murals/Aloft mural/Aloftmuralprogress8.webp"
//
import AmeliaIsland1 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_beach_diorama.webp"
import AmeliaIsland2 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_dock_mural_in_studio_1.webp"
import AmeliaIsland3 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_dock_mural_installed_detail.webp"
import AmeliaIsland4 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_dock_mural_installed.webp"
import AmeliaIsland5 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_Port_building_window_mural_1.webp"
import AmeliaIsland6 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_Port_building_window_mural_2.webp"
import AmeliaIsland7 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_Port_diorama THUMBNAIL.webp"
import AmeliaIsland8 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Spanish_Port_diorama_detail_3.webp"
import AmeliaIsland9 from "assets/img/murals/Amelia Island Museum of History murals 2004/Amelia_Island_FL_History_Museum_Timucuan_Indian_Village_diorama.webp"
//
import AshevilleBrewing1 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AsheBrewingbarmuraldetail2THUMBNAIL.webp"
import AshevilleBrewing2 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AshevilleBrewingbarwallpre.webp"
import AshevilleBrewing3 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AshevilleBrewingpipemuraldetail1.webp"
import AshevilleBrewing4 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AshevilleBrewingpipemuraldetail2.webp"
import AshevilleBrewing5 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AshevilleBrewingpipemuraldetail3.webp"
import AshevilleBrewing6 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AshevilleBrewingpipemuralfinished2.webp"
import AshevilleBrewing7 from "assets/img/murals/AshevilleBrewingCoBarbackSteamPunk/AshevilleBrewingpipemuralpanelinstudio.webp"
//
import AshevilleBrewingComic1 from "assets/img/murals/AshevilleBrewingCoComicBook/AsheBrewingplanetmuralinstalled.webp"
import AshevilleBrewingComic2 from "assets/img/murals/AshevilleBrewingCoComicBook/AshevilleBrewingplanetmuraldetail2.webp"
import AshevilleBrewingComic3 from "assets/img/murals/AshevilleBrewingCoComicBook/AshevilleBrewingplanetmuraldetail3.webp"
import AshevilleBrewingComic4 from "assets/img/murals/AshevilleBrewingCoComicBook/AshevilleBrewingplanetmuralinprogress.webp"
import AshevilleBrewingComic5 from "assets/img/murals/AshevilleBrewingCoComicBook/AshevilleBrewingplanetmuralstudio.webp"
//
import AshevilleBrewingCard1 from "assets/img/murals/AshevilleBrewingCoPostcard/AsheBrewingpostcarddetail.webp"
import AshevilleBrewingCard2 from "assets/img/murals/AshevilleBrewingCoPostcard/AshevilleBrewingpostcardmuralinstalled.webp"
import AshevilleBrewingCard3 from "assets/img/murals/AshevilleBrewingCoPostcard/AshevilleBrewingpostcardmuralinstalling.webp"
import AshevilleBrewingCard4 from "assets/img/murals/AshevilleBrewingCoPostcard/AshevilleBrewingpostcardstylepaintedmural.webp"
import AshevilleBrewingCard5 from "assets/img/murals/AshevilleBrewingCoPostcard/AshevilleBrewingpostcardstylepaintedmuraldetail.webp"
import AshevilleBrewingCard6 from "assets/img/murals/AshevilleBrewingCoPostcard/AshevilleBrewingpostcardstylepaintedmuralprogress2.webp"
//
import BMCAMural1 from "assets/img/murals/Black Mountain Center for the Arts/01BMCAmuralprep.webp"
import BMCAMural2 from "assets/img/murals/Black Mountain Center for the Arts/02BMCAmuralprep.webp"
import BMCAMural3 from "assets/img/murals/Black Mountain Center for the Arts/03BMCAmuralprogress.webp"
import BMCAMural4 from "assets/img/murals/Black Mountain Center for the Arts/04BMCAmuralprogressbasecolors.webp"
import BMCAMural5 from "assets/img/murals/Black Mountain Center for the Arts/05BMCAmuralprogress.webp"
import BMCAMural6 from "assets/img/murals/Black Mountain Center for the Arts/06BMCAmuralprogress.webp"
import BMCAMural7 from "assets/img/murals/Black Mountain Center for the Arts/07BMCAmuralprogress.webp"
import BMCAMural8 from "assets/img/murals/Black Mountain Center for the Arts/08BMCAmuralprogress.webp"
import BMCAMural9 from "assets/img/murals/Black Mountain Center for the Arts/09BMCAmuraldetail.webp"
import BMCAMural10 from "assets/img/murals/Black Mountain Center for the Arts/10BMCAmuraldetail.webp"
import BMCAMural11 from "assets/img/murals/Black Mountain Center for the Arts/11BMCAmuraldetail.webp"
import BMCAMural12 from "assets/img/murals/Black Mountain Center for the Arts/12BMCAmuralinstallation.webp"
import BMCAMural13 from "assets/img/murals/Black Mountain Center for the Arts/13BMCAmuralfinishedinstall.webp"
import BMCAMural14 from "assets/img/murals/Black Mountain Center for the Arts/14BMCAmuralfinished.webp"
import BMCAMural15 from "assets/img/murals/Black Mountain Center for the Arts/15BMCAmuralfinished.webp"
import BMCAMural16 from "assets/img/murals/Black Mountain Center for the Arts/16BMCAmuraleveningTHUMBNAIL.webp"
import BMCAMural17 from "assets/img/murals/Black Mountain Center for the Arts/16BMCAmuralfinished.webp"
//
import BonfirePBR1 from "assets/img/murals/Bonfire PBR/Bonfireinstalled.webp"
import BonfirePBR2 from "assets/img/murals/Bonfire PBR/BonfirePBRretrowallmockupvers2.webp"
import BonfirePBR3 from "assets/img/murals/Bonfire PBR/IMG_20180302_165830.webp"
import BonfirePBR4 from "assets/img/murals/Bonfire PBR/IMG_20180303_145025.webp"
import BonfirePBR5 from "assets/img/murals/Bonfire PBR/IMG_20180303_201748.webp"
import BonfirePBR6 from "assets/img/murals/Bonfire PBR/IMG_20180315_183531.webp"
import BonfirePBR7 from "assets/img/murals/Bonfire PBR/IMG_20180315_192832.webp"
import BonfirePBR8 from "assets/img/murals/Bonfire PBR/IMG_20180316_221752.webp"
import BonfirePBR9 from "assets/img/murals/Bonfire PBR/IMG_20180319_022621.webp"
import BonfirePBR10 from "assets/img/murals/Bonfire PBR/IMG_20180319_025912.webp"
//
import ChrisCornell1 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/01EchoMountainmuralstudioprep.webp"
import ChrisCornell2 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/02EchoMountainmuralinitiallayout.webp"
import ChrisCornell3 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/03EchoMountainmuralprogress.webp"
import ChrisCornell4 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/04EchoMountainmuralprogress.webp"
import ChrisCornell5 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/05EchoMountainbackroundpainting.webp"
import ChrisCornell6 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/06EchoMountainmuralfinisheddetail.webp"
import ChrisCornell7 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/07EchoMountainmuralfinisheddetail.webp"
import ChrisCornell8 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/08EchoMountainmuralinstallatiobeginning.webp"
import ChrisCornell9 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/09EchoMountainmuralfinishinginstallationTHUMBNAIL.webp"
import ChrisCornell10 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/10EchoMountainmuralfinalinstallation.webp"
import ChrisCornell11 from "assets/img/murals/Chris Cornell mural Echo Mountain Recording Studio/11EchoMountainmuralfinal.webp"
//
import ServerMural1 from "assets/img/murals/computer server mural/IMG_20190808_224103.webp"
import ServerMural2 from "assets/img/murals/computer server mural/IMG_20190827_143110.webp"
import ServerMural3 from "assets/img/murals/computer server mural/logowallmockup1.webp"
import ServerMural4 from "assets/img/murals/computer server mural/logowallmockup2.webp"
import ServerMural5 from "assets/img/murals/computer server mural/logowallmockup3.webp"
import ServerMural6 from "assets/img/murals/computer server mural/realtechfinalmockup7-18.webp"
//
import CumberlandKY1 from "assets/img/murals/Cumberland KY ghost sign restoration/IMG_3349.webp"
import CumberlandKY2 from "assets/img/murals/Cumberland KY ghost sign restoration/IMG_20200611_135111.webp"
import CumberlandKY3 from "assets/img/murals/Cumberland KY ghost sign restoration/IMG_20200613_192136.webp"
import CumberlandKY4 from "assets/img/murals/Cumberland KY ghost sign restoration/IMG_20200616_113102.webp"
//
import DiscoveryKids1 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_detail_1_Huntersville_NC_2012.webp"
import DiscoveryKids2 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_detail_2_Huntersville_NC_2012.webp"
import DiscoveryKids3 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_detail_3_Huntersville_NC_2012.webp"
import DiscoveryKids4 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_detail_4_Huntersville_NC_2012.webp"
import DiscoveryKids5 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_detail_5_Huntersville_NC_2012.webp"
import DiscoveryKids6 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_detail_6_Huntersville_NC_2012.webp"
import DiscoveryKids7 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_Final_Huntersville_NC_2012.webp"
import DiscoveryKids8 from "assets/img/murals/Discovery Kids Museum/Discovery_Kids_Museum_undersea_mural_in_progress_Huntersville_NC_2012.webp"
//
import ForestCity1 from "assets/img/murals/Forest City NC Ghost sign reproduction/20200810_111024.webp"
import ForestCity2 from "assets/img/murals/Forest City NC Ghost sign reproduction/20200810_131022.webp"
import ForestCity3 from "assets/img/murals/Forest City NC Ghost sign reproduction/20200810_172209.webp"
import ForestCity4 from "assets/img/murals/Forest City NC Ghost sign reproduction/20200811_182024.webp"
//
import FortFisher1 from "assets/img/murals/Ft Fisher/Fort_Fisher_History_Museum_Kure_Beach_NC_mural_installed_1.webp"
import FortFisher2 from "assets/img/murals/Ft Fisher/Fort_Fisher_History_Museum_Kure_Beach_NC_mural_installed_4.webp"
import FortFisher3 from "assets/img/murals/Ft Fisher/Fort_Fisher_History_Museum_Kure_Beach_NC_mural_installed_detail_2.webp"
import FortFisher4 from "assets/img/murals/Ft Fisher/Fort_Fisher_History_Museum_Kure_Beach_NC_mural_installed_detail_3.webp"
//
import FtMacon1 from "assets/img/murals/Ft Macon History Museum/Fort_Macon_History_Museum_Atlantic_Beach_NC_marsh_installed.webp"
import FtMacon2 from "assets/img/murals/Ft Macon History Museum/Ft_Macon_History_Museum_diorama_detail_beach_side_installed_acrylic_on_canvas_2010.webp"
import FtMacon3 from "assets/img/murals/Ft Macon History Museum/Ft_Macon_History_Museum_diorama_detail_in_studio_1_acrylic_on_canvas_2010.webp"
import FtMacon4 from "assets/img/murals/Ft Macon History Museum/Ft_Macon_History_Museum_diorama_detail_in_studio_2_acrylic_on_canvas_2010.webp"
import FtMacon5 from "assets/img/murals/Ft Macon History Museum/Ft_Macon_History_Museum_diorama_detail_in_studio_in_progress_1_acrylic_on_canvas_2010.webp"
import FtMacon6 from "assets/img/murals/Ft Macon History Museum/Ft_Macon_History_Museum_diorama_detail_in_studio_in_progress_2_acrylic_on_canvas_2010.webp"
//
import GaiaHerbs1 from "assets/img/murals/Gaia Herbs/IMG_20191019_220000_1.webp"
import GaiaHerbs2 from "assets/img/murals/Gaia Herbs/IMG_20191019_220010.webp"
import GaiaHerbs3 from "assets/img/murals/Gaia Herbs/IMG_20191019_220056.webp"
//
import LoganBros1 from "assets/img/murals/Logan Bros Haywood Rd mural/01LoganBrothersHaywoodRdmuralinitiallayout.webp"
import LoganBros2 from "assets/img/murals/Logan Bros Haywood Rd mural/02LoganBrothersHaywoodRdmuralbasecoating.webp"
import LoganBros3 from "assets/img/murals/Logan Bros Haywood Rd mural/03LoganBrothersHaywoodRdmuralprogress.webp"
import LoganBros4 from "assets/img/murals/Logan Bros Haywood Rd mural/04LoganBrothersHaywoodRdmuralprogress.webp"
import LoganBros5 from "assets/img/murals/Logan Bros Haywood Rd mural/05LoganBrothersHaywoodRdmuralprogress.webp"
import LoganBros6 from "assets/img/murals/Logan Bros Haywood Rd mural/06LoganBrothersHaywoodRdmuraldetail.webp"
import LoganBros7 from "assets/img/murals/Logan Bros Haywood Rd mural/07LoganBrothersHaywoodRdmuraldetail.webp"
import LoganBros8 from "assets/img/murals/Logan Bros Haywood Rd mural/08LoganBrothersHaywoodRdmuralfinishedmuralTHUMBNAIL.webp"
//
import MtMitchell1 from "assets/img/murals/Mt Mitchell State Park Museum/Mt_Mitchell_State_Park_Museum_diorama_final_in_the_studio_2016.webp"
import MtMitchell2 from "assets/img/murals/Mt Mitchell State Park Museum/Mt_Mitchell_State_Park_Museum_diorama_installed_2016.webp"
import MtMitchell3 from "assets/img/murals/Mt Mitchell State Park Museum/Mt_Mitchell_State_Park_Museum_diorama_progress_in_the_studio_2_2016.webp"
//
import Pennsbury1 from "assets/img/murals/Pennsbury/Pennsbury_Manor_Museum_Morrisville_PA_diorama_background_in_finished_in_studio_2.webp"
import Pennsbury2 from "assets/img/murals/Pennsbury/Pennsbury_Manor_Museum_Morrisville_PA_diorama_background_in_finished_in_studio_3.webp"
import Pennsbury3 from "assets/img/murals/Pennsbury/Pennsbury_Manor_Museum_Morrisville_PA_diorama_background_in_progress_1.webp"
import Pennsbury4 from "assets/img/murals/Pennsbury/Pennsbury_Manor_Museum_Morrisville_PA_diorama_background_installed.webp"
//
import RiverbendMalt1 from "assets/img/murals/Riverbend Malt Mural/Riverbend_Malt_billhead_style_mural_finished_Asheville_NC_2018.webp"
import RiverbendMalt2 from "assets/img/murals/Riverbend Malt Mural/Riverbend_Malt_billhead_style_mural_progess_0.1_Asheville_NC_2018.webp"
import RiverbendMalt3 from "assets/img/murals/Riverbend Malt Mural/Riverbend_Malt_billhead_style_mural_progess_1_Asheville_NC_2018.webp"
import RiverbendMalt4 from "assets/img/murals/Riverbend Malt Mural/Riverbend_Malt_billhead_style_mural_progess_2_Asheville_NC_2018.webp"
import RiverbendMalt5 from "assets/img/murals/Riverbend Malt Mural/Riverbend_Malt_billhead_style_mural_progess_3_Asheville_NC_2018.webp"


//Gallery specific front page images
export const MuralsGalleryFrontImgs = [
    {
        imgAlt: "Aloft Mural",
        image: AloftMural3,
        width: 200,
        height: 400,
        key: 0,
        title: "Aloft Hotel Stairwell",
        isFeatured: true,
    },
    {
        imgAlt: "Amelia Island Museum of History",
        image: AmeliaIsland7,
        width: 200,
        height: 400,
        key: 1,
        title: "Amelia Island Museum of History",
    },
    {
        imgAlt: "Asheville Brewing Co. Barback",
        image: AshevilleBrewing1,
        width: 200,
        height: 400,
        key: 2,
        title: "Asheville Brewing Co. Barback",
    },
    {
        imgAlt: "Asheville Brewing Co. Comic Book",
        image: AshevilleBrewingComic5,
        width: 200,
        height: 200,
        key: 3,
        title: "Asheville Brewing Co. Comic Book",
    },
    {
        imgAlt: "Asheville Brewing Co. Post Card",
        image: AshevilleBrewingCard4,
        width: 200,
        height: 300,
        key: 4,
        title: "Asheville Brewing Co. Post Card",
    },
    {
        imgAlt: "Black Mountain Center for the Arts",
        image: BMCAMural16,
        width: 200,
        height: 400,
        key: 5,
        title: "Black Mountain Center for the Arts",
        isFeatured: true,
    },
    {
        imgAlt: "Bonfire BBQ wall mural",
        image: BonfirePBR10,
        width: 200,
        height: 200,
        key: 6,
        title: "Bonfire BBQ",
        isFeatured: true,
    },
    {
        imgAlt: "Echo Mountain Recording Studio - Chris Cornell mural",
        image: ChrisCornell9,
        width: 200,
        height: 400,
        key: 7,
        title: "Echo Mountain Recording Studio",
        isFeatured: true,
    },
    {
        imgAlt: "Computer Server Mural",
        image: ServerMural1,
        width: 200,
        height: 400,
        key: 8,
        title: "Computer Server",
    },
    {
        imgAlt: "Cumberland KY Royal Cola Ghost Sign",
        image: CumberlandKY1,
        width: 200,
        height: 200,
        key: 9,
        title: "Cumberland KY Royal Crown Ghost Sign",
        isFeatured: true,
    },
    {
        imgAlt: "Discovery Kids Museum undersea mural",
        image: DiscoveryKids3,
        width: 200,
        height: 400,
        key: 10,
        title: "Discovery Kids Museum",
    },
    {
        imgAlt: "Forest City Ghost Sign",
        image: ForestCity4,
        width: 200,
        height: 600,
        key: 11,
        title: "Forest City Ghost Sign",
    },
    {
        imgAlt: "Fort Fisher History Museum",
        image: FortFisher2,
        width: 200,
        height: 200,
        key: 12,
        title: "Fort Fisher History Museum",
    },
    {
        imgAlt: "Fort Macon History Museum Diorama",
        image: FtMacon2,
        width: 200,
        height: 200,
        key: 13,
        title: "Fort Macon History Museum Diorama",
    },
    {
        imgAlt: "Gaia Herbs",
        image: GaiaHerbs2,
        width: 200,
        height: 200,
        key: 14,
        title: "Gaia Herbs",
    },
    {
        imgAlt: "Logan Brothers Mural",
        image: LoganBros8,
        width: 200,
        height: 200,
        key: 15,
        title: "Logan Brothers Mural",
        isFeatured: true,
    },
    {
        imgAlt: "Mt. Mitchell State Park Museum Diorama",
        image: MtMitchell1,
        width: 200,
        height: 500,
        key: 16,
        title: "Mt. Mitchell State Park Museum Diorama",
    },
    {
        imgAlt: "Pensbury Manor Museum",
        image: Pennsbury2,
        width: 200,
        height: 400,
        key: 17,
        title: "Pensbury Manor Museum",
    },
    {
        imgAlt: "Riverbend Malt",
        image: RiverbendMalt1,
        width: 200,
        height: 400,
        key: 18,
        title: "Riverbend Malt",
        isFeatured: true,
    },
];

// Galleries in order
export const AloftGallery = [
    {
        src: AloftMural1,
        key: 0,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural2,
        width: 3,
        height: 2,
        key: 1,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural3,
        width: 3,
        height: 2,
        key: 2,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural4,
        width: 3,
        height: 2,
        key: 3,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural5,
        width: 3,
        height: 2,
        key: 4,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural6,
        width: 3,
        height: 2,
        key: 5,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural7,
        width: 3,
        height: 2,
        key: 6,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural8,
        width: 3,
        height: 2,
        key: 7,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural9,
        width: 3,
        height: 2,
        key: 8,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural10,
        width: 3,
        height: 2,
        key: 9,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural11,
        width: 3,
        height: 2,
        key: 10,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural12,
        width: 3,
        height: 2,
        key: 11,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
    {
        src: AloftMural13,
        width: 3,
        height: 2,
        key: 12,
        alt: "Aloft Mural",
        caption: "Aloft Hotel stairwell mural 2020. Designed to fit with the new local music theme of the hotel. Comemorates 50 years of iconic concerts in Asheville. 10x20 Acrylic on Dibond panels with urethane clear coat"
    },
];

export const AmeliaIslandGallery = [
    {
        src: AmeliaIsland1,
        width: 3,
        height: 2,
        key: 0,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland2,
        width: 3,
        height: 2,
        key: 1,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland3,
        width: 3,
        height: 2,
        key: 2,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland4,
        width: 3,
        height: 2,
        key: 3,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland5,
        width: 3,
        height: 2,
        key: 4,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland6,
        width: 3,
        height: 2,
        key: 5,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland7,
        width: 3,
        height: 2,
        key: 6,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland8,
        width: 3,
        height: 2,
        key: 7,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
    {
        src: AmeliaIsland9,
        width: 3,
        height: 2,
        key: 8,
        alt: "Amelia Island Museum of History",
        caption: "Amelia Island Museum of History murals/dioramas 2004. Series of 5 murals Acrylic on canvas installed on site"
    },
];
export const AshevilleBrewingBarGallery = [
    {
        src: AshevilleBrewing1,
        width: 3,
        height: 2,
        key: 0,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
    {
        src: AshevilleBrewing2,
        width: 3,
        height: 2,
        key: 1,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
    {
        src: AshevilleBrewing3,
        width: 3,
        height: 2,
        key: 2,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
    {
        src: AshevilleBrewing4,
        width: 3,
        height: 2,
        key: 3,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
    {
        src: AshevilleBrewing5,
        width: 3,
        height: 2,
        key: 4,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
    {
        src: AshevilleBrewing6,
        width: 3,
        height: 2,
        key: 5,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
    {
        src: AshevilleBrewing7,
        width: 3,
        height: 2,
        key: 6,
        alt: "Asheville Brewing Co. Barback",
        caption: "Asheville Brewing Co. Barback"
    },
];
export const AshevilleBrewingComicGallery = [
    {
        src: AshevilleBrewingComic1,
        width: 3,
        height: 2,
        key: 0,
        alt: "Asheville Brewing Co. Comic Book",
        caption: "Asheville Brewing Co. Comic Book"
    },
    {
        src: AshevilleBrewingComic2,
        width: 3,
        height: 2,
        key: 1,
        alt: "Asheville Brewing Co. Comic Book",
        caption: "Asheville Brewing Co. Comic Book"
    },
    {
        src: AshevilleBrewingComic3,
        width: 3,
        height: 2,
        key: 2,
        alt: "Asheville Brewing Co. Comic Book",
        caption: "Asheville Brewing Co. Comic Book"
    },
    {
        src: AshevilleBrewingComic4,
        width: 3,
        height: 2,
        key: 3,
        alt: "Asheville Brewing Co. Comic Book",
        caption: "Asheville Brewing Co. Comic Book"
    },
    {
        src: AshevilleBrewingComic5,
        width: 3,
        height: 2,
        key: 4,
        alt: "Asheville Brewing Co. Comic Book",
        caption: "Asheville Brewing Co. Comic Book"
    },
];
export const AshevilleBrewingCardGallery = [
    {
        src: AshevilleBrewingCard1,
        width: 3,
        height: 2,
        key: 0,
        alt: "Asheville Brewing Co. Post Card",
        caption: "Asheville Brewing Co. Post Card"
    },
    {
        src: AshevilleBrewingCard2,
        width: 3,
        height: 2,
        key: 1,
        alt: "Asheville Brewing Co. Post Card",
        caption: "Asheville Brewing Co. Post Card"
    },
    {
        src: AshevilleBrewingCard3,
        width: 3,
        height: 2,
        key: 2,
        alt: "Asheville Brewing Co. Post Card",
        caption: "Asheville Brewing Co. Post Card"
    },
    {
        src: AshevilleBrewingCard4,
        width: 3,
        height: 2,
        key: 3,
        alt: "Asheville Brewing Co. Post Card",
        caption: "Asheville Brewing Co. Post Card"
    },
    {
        src: AshevilleBrewingCard5,
        width: 3,
        height: 2,
        key: 4,
        alt: "Asheville Brewing Co. Post Card",
        caption: "Asheville Brewing Co. Post Card"
    },
    {
        src: AshevilleBrewingCard6,
        width: 3,
        height: 2,
        key: 5,
        alt: "Asheville Brewing Co. Post Card",
        caption: "Asheville Brewing Co. Post Card"
    },
];
export const BMCAGallery = [
    {
        src: BMCAMural1,
        key: 0,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural2,
        key: 1,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural3,
        key: 2,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural4,
        key: 3,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural5,
        key: 4,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural6,
        key: 5,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural7,
        key: 6,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural8,
        key: 7,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural9,
        key: 8,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural10,
        key: 9,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural11,
        key: 10,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural12,
        key: 11,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural13,
        key: 12,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural14,
        key: 13,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural15,
        key: 14,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural16,
        key: 15,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: BMCAMural17,
        key: 16,
        alt: "Black Mountain Center for the Arts",
        caption: "Black Mountain Center for the Arts mural 2019. Design inspired by WPA muralist Thomas Hart Benton. The image portrays all of the activities that the the center provides for the community. 30x52 Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
];
export const BonfirePBRGallery = [
    {
        src: BonfirePBR1,
        key: 0,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR2,
        key: 1,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR3,
        key: 2,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR4,
        key: 3,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR5,
        key: 4,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR6,
        key: 5,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR7,
        key: 6,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR8,
        key: 7,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR9,
        key: 8,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
    {
        src: BonfirePBR10,
        key: 9,
        alt: "Bonfire BBQ wall mural",
        caption: "Bonfire BBQ wall mural"
    },
];
export const ChrisCornellGallery = [
    {
        src: ChrisCornell1,
        key: 0,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell2,
        key: 1,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell3,
        key: 2,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell4,
        key: 3,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell5,
        key: 4,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell6,
        key: 5,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell7,
        key: 6,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell8,
        key: 7,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell9,
        key: 8,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell10,
        key: 9,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
    {
        src: ChrisCornell11,
        key: 10,
        alt: "Echo Mountain Recording Studio - Chris Cornell mural",
        caption: "Echo Mountain Recording Studio - Chris Cornell mural. 2017 Based on Charles Peterson's iconic photograph (used by permission) 33'x29' Acrylic on polytab. Painted offsite and permanently applied to the wall"
    },
];
export const ServerGallery = [
    {
        src: ServerMural1,
        key: 0,
        alt: "Computer Server",
        caption: "Computer Server Mural"
    },
    {
        src: ServerMural2,
        key: 1,
        alt: "Computer Server",
        caption: "Computer Server Mural"
    },
    {
        src: ServerMural3,
        key: 2,
        alt: "Computer Server",
        caption: "Computer Server Mural"
    },
    {
        src: ServerMural4,
        key: 3,
        alt: "Computer Server",
        caption: "Computer Server Mural"
    },
    {
        src: ServerMural5,
        key: 4,
        alt: "Computer Server",
        caption: "Computer Server Mural"
    },
    {
        src: ServerMural6,
        key: 5,
        alt: "Computer Server",
        caption: "Computer Server Mural"
    },
];
export const CumberlandKYGallery = [
    {
        src: CumberlandKY1,
        key: 0,
        alt: "Cumberland KY Royal Cola Ghost Sign",
        caption: "Cumberland KY Royal Cola Ghost Sign"
    },
    {
        src: CumberlandKY2,
        key: 1,
        alt: "Cumberland KY Royal Cola Ghost Sign",
        caption: "Cumberland KY Royal Cola Ghost Sign"
    },
    {
        src: CumberlandKY3,
        key: 2,
        alt: "Cumberland KY Royal Cola Ghost Sign",
        caption: "Cumberland KY Royal Cola Ghost Sign"
    },
    {
        src: CumberlandKY4,
        key: 3,
        alt: "Cumberland KY Royal Cola Ghost Sign",
        caption: "Cumberland KY Royal Cola Ghost Sign"
    },
];
export const DiscoveryKidsGallery = [
    {
        src: DiscoveryKids1,
        key: 0,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids2,
        key: 1,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids3,
        key: 2,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids4,
        key: 3,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids5,
        key: 4,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids6,
        key: 5,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids7,
        key: 6,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
    {
        src: DiscoveryKids8,
        key: 7,
        alt: "Discovery Kids Museum undersea mural",
        caption: "Discovery Kids Museum undersea mural"
    },
];
export const ForestCityGallery = [
    {
        src: ForestCity1,
        key: 0,
        alt: "Forest City Ghost Sign",
        caption: "Forest City Ghost Sign"
    },
    {
        src: ForestCity2,
        key: 1,
        alt: "Forest City Ghost Sign",
        caption: "Forest City Ghost Sign"
    },
    {
        src: ForestCity3,
        key: 2,
        alt: "Forest City Ghost Sign",
        caption: "Forest City Ghost Sign"
    },
    {
        src: ForestCity4,
        key: 3,
        alt: "Forest City Ghost Sign",
        caption: "Forest City Ghost Sign"
    },
];
export const FortFisherGallery = [
    {
        src: FortFisher1,
        key: 0,
        alt: "Fort Fisher History Museum",
        caption: "Fort Fisher History Museum"
    },
    {
        src: FortFisher2,
        key: 1,
        alt: "Fort Fisher History Museum",
        caption: "Fort Fisher History Museum"
    },
    {
        src: FortFisher3,
        key: 2,
        alt: "Fort Fisher History Museum",
        caption: "Fort Fisher History Museum"
    },
    {
        src: FortFisher4,
        key: 3,
        alt: "Fort Fisher History Museum",
        caption: "Fort Fisher History Museum"
    },
];
export const FtMaconGallery = [
    {
        src: FtMacon1,
        key: 0,
        alt: "Fort Macon History Museum Diorama",
        caption: "Fort Macon History Museum Diorama"
    },
    {
        src: FtMacon2,
        key: 1,
        alt: "Fort Macon History Museum Diorama",
        caption: "Fort Macon History Museum Diorama"
    },
    {
        src: FtMacon3,
        key: 2,
        alt: "Fort Macon History Museum Diorama",
        caption: "Fort Macon History Museum Diorama"
    },
    {
        src: FtMacon4,
        key: 3,
        alt: "Fort Macon History Museum Diorama",
        caption: "Fort Macon History Museum Diorama"
    },
    {
        src: FtMacon5,
        key: 4,
        alt: "Fort Macon History Museum Diorama",
        caption: "Fort Macon History Museum Diorama"
    },
    {
        src: FtMacon6,
        key: 5,
        alt: "Fort Macon History Museum Diorama",
        caption: "Fort Macon History Museum Diorama"
    },
];
export const GaiaHerbsGallery = [
    {
        src: GaiaHerbs1,
        key: 0,
        alt: "Gaia Herbs",
        caption: "Gaia Herbs"
    },
    {
        src: GaiaHerbs2,
        key: 1,
        alt: "Gaia Herbs",
        caption: "Gaia Herbs"
    },
    {
        src: GaiaHerbs3,
        key: 2,
        alt: "Gaia Herbs",
        caption: "Gaia Herbs"
    },
];
export const LoganGallery = [
    {
        src: LoganBros1,
        key: 0,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros2,
        key: 1,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros3,
        key: 2,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros4,
        key: 3,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros5,
        key: 4,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros6,
        key: 5,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros7,
        key: 6,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
    {
        src: LoganBros8,
        key: 7,
        alt: "Logan Brothers Mural",
        caption: "Logan Brothers Mural Haywood Rd."
    },
];
export const MtMitchelllGallery = [
    {
        src: MtMitchell1,
        key: 0,
        alt: "Mt. Mitchell State Park Museum Diorama",
        caption: "Mt. Mitchell State Park Museum Diorama"
    },
    {
        src: MtMitchell2,
        key: 1,
        alt: "Mt. Mitchell State Park Museum Diorama",
        caption: "Mt. Mitchell State Park Museum Diorama"
    },
    {
        src: MtMitchell3,
        key: 2,
        alt: "Mt. Mitchell State Park Museum Diorama",
        caption: "Mt. Mitchell State Park Museum Diorama"
    },
];
export const PennsburyGallery = [
    {
        src: Pennsbury1,
        key: 0,
        alt: "Pensbury Manor Museum",
        caption: "Pensbury Manor Museum"
    },
    {
        src: Pennsbury2,
        key: 1,
        alt: "Pensbury Manor Museum",
        caption: "Pensbury Manor Museum"
    },
    {
        src: Pennsbury3,
        key: 2,
        alt: "Pensbury Manor Museum",
        caption: "Pensbury Manor Museum"
    },
    {
        src: Pennsbury4,
        key: 3,
        alt: "Pensbury Manor Museum",
        caption: "Pensbury Manor Museum"
    },
];
export const RiverbendMaltGallery = [
    {
        src: RiverbendMalt1,
        key: 0,
        alt: "Riverbend Malt",
        caption: "Riverbend Malt Billhead Style Mural"
    },
    {
        src: RiverbendMalt2,
        key: 1,
        alt: "Riverbend Malt",
        caption: "Riverbend Malt Billhead Style Mural"
    },
    {
        src: RiverbendMalt3,
        key: 2,
        alt: "Riverbend Malt",
        caption: "Riverbend Malt Billhead Style Mural"
    },
    {
        src: RiverbendMalt4,
        key: 3,
        alt: "Riverbend Malt",
        caption: "Riverbend Malt Billhead Style Mural"
    },
    {
        src: RiverbendMalt5,
        key: 4,
        alt: "Riverbend Malt",
        caption: "Riverbend Malt Billhead Style Mural"
    },
];
