import React from 'react'
import { useLightbox } from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
import Button from '@material-ui/core/Button';
import "../masonary.css";

/*
We can use the provided hook in case you want
to open the lightbox from a button or anything :)
*/

function LB_Button(props) {
  // Information is passed from MyMasonaryItem to here 
  const { openLightbox } = useLightbox()

  return (
      <div className={"container"} style={props.style}>
        <Button
          style={{
            width: props.style.width,
            height: props.style.height,
          }}
        onClick={() => openLightbox()}
      >
        <img 
          src={props.src} 
          alt="Avatar" 
          className="image"
          key={props.frontImageKey}
        />
        <div className="overlay">
          <div className="text">{props.title}</div>
        </div>
      </Button>
      <SRLWrapper images={props.gallery}/>
    </div>

  )
}
export default LB_Button;