import AlbumCover from "assets/img/conceptArt/album_cover_concept_sketch_2020.webp";
import BBQMenu from "assets/img/conceptArt/bbq_restaurant_menu_illustration_2015.webp";
import CarnivalBBQ1 from "assets/img/conceptArt/carnival_style_rendering_for_bbq_restaurant_2_2016.webp";
import CarnivalBBQ2 from "assets/img/conceptArt/carnival_style_rendering_for_bbq_restaurant_2016.webp";
import ToyStore from "assets/img/conceptArt/concept_for_toy_store_mural_of_vintage_toys_2012.webp";
import VintagePoster from "assets/img/conceptArt/concept_for_vintage_poster_style_mural_2018.webp";
import BreweryMural from "assets/img/conceptArt/concept_sketch_for_brewery_mural_2017.webp";
import WarehouseStudio from "assets/img/conceptArt/concepts_for_artist_warehouse_studios_sign_2019.webp";
import DwntChildrens from "assets/img/conceptArt/downtown_childrens_mural_2003.webp";
import ExteriorGhostSign from "assets/img/conceptArt/exterior_ghost_sign_style_layout_2019.webp";
import HunterBanks from "assets/img/conceptArt/final_rendering_for_hunter_banks_mural_2011.webp";
import GardenStoreTruck from "assets/img/conceptArt/garden_store_delivery_truck_graphic_concepts_2020.webp";
import BakerySign from "assets/img/conceptArt/hand_lettered_concept_for_bakery_sign_2020.webp";
import VintageDeliveryTruck from "assets/img/conceptArt/hand_lettered_layout_for_vintage_delivery_truck.webp";
import BrushCase from "assets/img/conceptArt/hand_lettered_layout_sketch_for_brush_case_2020.webp";
import OfficeBuildingMural from "assets/img/conceptArt/hand_lettered_mural_concept_for_historic_office_building_2019.webp";
import ResturantMenu from "assets/img/conceptArt/hand_lettered_restaurant_menu_background_2018.webp";
import HotelLobbyMural from "assets/img/conceptArt/hotel_lobby_mural_initial_concept_2020.webp";
import DistilleryMural1 from "assets/img/conceptArt/initial_concept_for_distillery_mural_2018.webp";
import DistilleryMural2 from "assets/img/conceptArt/initial_concept_for_distillery_mural_letterhead_2018.webp";
import PosterArtRetrospective from "assets/img/conceptArt/initial_concept_for_poster_art_of_my_retrospective_show_2017.webp";
import ItalianRestMural from "assets/img/conceptArt/italian_restaurant_mural_rendering_2003.webp";
import LibraryMural from "assets/img/conceptArt/library_mural_concept_2016.webp";
import BBQMural from "assets/img/conceptArt/mural_concept_for_bbq_restaurant_classic_cartoon_style_2017.webp";
import MuseumDiorama1 from "assets/img/conceptArt/museum_diorama_mural_concept_2001.webp";
import MuseumDiorama2 from "assets/img/conceptArt/museum_diorama_mural_concept_2002.webp";
import MuseumDiorama3 from "assets/img/conceptArt/museum_diorama_mural_concept_2007.webp";
import MuseumMural from "assets/img/conceptArt/museum_diorama_mural_concept_2008.webp";
import ScrollSignBorder from "assets/img/conceptArt/scroll_rendering_for_sign_border_2015.webp";
import SpanishResturantMural from "assets/img/conceptArt/spanish_restaurant_initial_mural_concept_2018.webp";
import TacoStandMenu from "assets/img/conceptArt/taco_stand_menu_illustration_2017.webp";
import UnderwaterChildrensMeseum from "assets/img/conceptArt/underwater_childrens_museum_mural_rendering_2005.webp";
import YMCAMural from "assets/img/conceptArt/ymca_community_center_mural_concept_2018.webp";
//Folder
import BreweryTankMural1 from "assets/img/conceptArt/BreweryTankMural/brewery_tank_mural_rendering_1_2007.webp";
import BreweryTankMural2 from "assets/img/conceptArt/BreweryTankMural/brewery_tank_mural_rendering_2_2007.webp";
import BreweryTankMural3 from "assets/img/conceptArt/BreweryTankMural/brewery_tank_mural_rendering_3_2007.webp";
//
import BrushcanLogoConcept1 from "assets/img/conceptArt/BrushcanLogoConcept/Initial_hand_lettered_layout_for_shop_logo_1_2017.webp";
import BrushcanLogoConcept2 from "assets/img/conceptArt/BrushcanLogoConcept/initial_hand_lettered_layout_for_shop_logo_2_2017.webp";
//
import ChildrensHospitalMural1 from "assets/img/conceptArt/ChildrensHospitalMural/children_hospital_mural_concept_2_2003.webp";
import ChildrensHospitalMural2 from "assets/img/conceptArt/ChildrensHospitalMural/childrens_hospital_mural_concept_1_2003.webp";
//
import ChildrensMuseumMural1 from "assets/img/conceptArt/ChildrensMuseumMural/childrens_museum_mural_baby_dino_entry_sign_rendering_classic_cartoon_style.webp";
import ChildrensMuseumMural2 from "assets/img/conceptArt/ChildrensMuseumMural/childrens_museum_mural_baby_dino_rendering_classic_cartoon_style.webp";
import ChildrensMuseumMural3 from "assets/img/conceptArt/ChildrensMuseumMural/childrens_museum_mural_rendering_classic_cartoon_style.webp";
//
import HerbalCompany1 from "assets/img/conceptArt/HandLetteredHerbalCompany/concept_for_lobby_mural_at_herbal_medicine_company_2019.webp";
import HerbalCompany2 from "assets/img/conceptArt/HandLetteredHerbalCompany/hand_lettered_concept_for_herbal_medicine_company_1_2019.webp";
import HerbalCompany3 from "assets/img/conceptArt/HandLetteredHerbalCompany/hand_lettered_concept_for_herbal_medicine_company_2_2019.webp";
import HerbalCompany4 from "assets/img/conceptArt/HandLetteredHerbalCompany/hand_lettered_concept_for_herbal_medicine_company_3_2019.webp";
//
import HotelLobby1 from "assets/img/conceptArt/HotelLobbyMural/color_rendering_for_hotel_lobby_map_mural_2016.webp";
import HotelLobby2 from "assets/img/conceptArt/HotelLobbyMural/color_rendering_for_hotel_lobby_mural_2016.webp";
//
import MexicanResturant1 from "assets/img/conceptArt/MexicanResturant/mexican_restaurant_menu_illustration_concepts_2017.webp";
import MexicanResturant2 from "assets/img/conceptArt/MexicanResturant/mexican_restaurant_mural_concept_classic_cartoon_style_2017.webp";
//
import OutdoorOufitters1 from "assets/img/conceptArt/OutdoorOutfitterStoreMural/outdoor_outfitter_background_for_store_mural_2013.webp";
import OutdoorOufitters2 from "assets/img/conceptArt/OutdoorOutfitterStoreMural/outdoor_outfitter_multiple_concepts_for_store_mural_2013.webp";


//Gallery specific front page images 25 Images per section
export const ConceptArtGalleryFrontImgs = [
    {
        imgAlt: "album_cover_concept_sketch_2020",
        image: AlbumCover,
        width: 200,
        height: 400,
        key: 0,
        title: "Album cover concept sketch",
        // isFeatured: true,
    },
    {
        imgAlt: "bbq restaurant menu illustration 2015",
        image: BBQMenu,
        width: 200,
        height: 400,
        key: 1,
        title: "bbq restaurant menu illustration",
        // isFeatured: true,
    },
    {
        imgAlt: "carnival_style_rendering_for_bbq_restaurant_2_2016",
        image: CarnivalBBQ1,
        width: 200,
        height: 200,
        key: 2,
        title: "carnival style rendering for bbq restaurant",
        // isFeatured: true,
    },
    {
        imgAlt: "carnival_style_rendering_for_bbq_restaurant_2_2016",
        image: CarnivalBBQ2,
        width: 200,
        height: 200,
        key: 3,
        title: "carnival style rendering for bbq restaurant",
        // isFeatured: true,
    },
    {
        imgAlt: "concept_for_toy_store_mural_of_vintage_toys_2012",
        image: ToyStore,
        width: 200,
        height: 200,
        key: 4,
        title: "concept for toy store mural",
        // isFeatured: true,
    },
    {
        imgAlt: "concept_for_vintage_poster_style_mural_2018",
        image: VintagePoster,
        width: 200,
        height: 400,
        key: 5,
        title: "concept for vintage poster style mural",
        // isFeatured: true,
    },
    {
        imgAlt: "concept sketch for brewery mural 2017",
        image: BreweryMural,
        width: 200,
        height: 400,
        key: 6,
        title: "concept sketch for brewery mural",
        isFeatured: true,
    },
    {
        imgAlt: "concepts_for_artist_warehouse_studios_sign_2019",
        image: WarehouseStudio,
        width: 200,
        height: 400,
        key: 7,
        title: "concepts for artist warehouse studios",
        // isFeatured: true,
    },
    {
        imgAlt: "downtown_childrens_mural_2003",
        image: DwntChildrens,
        width: 200,
        height: 200,
        key: 8,
        title: "downtown childrens mural",
    },
    {
        imgAlt: "exterior_ghost_sign_style_layout_2019",
        image: ExteriorGhostSign,
        width: 200,
        height: 200,
        key: 9,
        title: "Exterior ghost sign style layout",
    },
    {
        imgAlt: "final_rendering_for_hunter_banks_mural_2011",
        image: HunterBanks,
        width: 200,
        height: 400,
        key: 10,
        title: "Final rendering for hunter banks mural",
        isFeatured: true,
    },
    {
        imgAlt: "garden_store_delivery_truck_graphic_concepts_2020",
        image: GardenStoreTruck,
        width: 200,
        height: 200,
        key: 11,
        title: "Delivery truck graphic concepts",
    },
    {
        imgAlt: "hand_lettered_concept_for_bakery_sign_2020",
        image: BakerySign,
        width: 200,
        height: 400,
        key: 12,
        title: "Concept for bakery sign 2020",
        isFeatured: true,
    },
    {
        imgAlt: "hand_lettered_layout_for_vintage_delivery_truck",
        image: VintageDeliveryTruck,
        width: 200,
        height: 200,
        key: 13,
        title: "Vintage delivery truck",
    },
    {
        imgAlt: "hand_lettered_layout_sketch_for_brush_case_2020",
        image: BrushCase,
        width: 200,
        height: 200,
        key: 14,
        title: "Sketch for brush case",
    },
    {
        imgAlt: "OfficeBuildingMural",
        image: OfficeBuildingMural,
        width: 200,
        height: 400,
        key: 15,
        title: "Concept for office building mural",
        isFeatured: true,
    },
    {
        imgAlt: "ResturantMenu",
        image: ResturantMenu,
        width: 200,
        height: 200,
        key: 16,
        title: "Restaurant menu background",
    },
    {
        imgAlt: "HotelLobbyMural",
        image: HotelLobbyMural,
        width: 200,
        height: 200,
        key: 17,
        title: "Hotel lobby mural concept",
    },
    {
        imgAlt: "DistilleryMural1",
        image: DistilleryMural1,
        width: 200,
        height: 200,
        key: 18,
        title: "Concept for distillery mural",
    },
    {
        imgAlt: "DistilleryMural2",
        image: DistilleryMural2,
        width: 200,
        height: 200,
        key: 19,
        title: "Concept for distillery mural",
    },
    {
        imgAlt: "PosterArtRetrospective",
        image: PosterArtRetrospective,
        width: 200,
        height: 400,
        key: 20,
        title: "Concept for poster art",
    },
    {
        imgAlt: "ItalianRestMuralGallery",
        image: ItalianRestMural,
        width: 200,
        height: 400,
        key: 21,
        title: "Italian restaurant mural",
    },
    {
        imgAlt: "LibraryMural",
        image: LibraryMural,
        width: 200,
        height: 200,
        key: 22,
        title: "Library mural concept",
    },
    {
        imgAlt: "BBQMural",
        image: BBQMural,
        width: 200,
        height: 200,
        key: 23,
        title: "Mural concept for bbq restaurant",
    },
    {
        imgAlt: "MuseumDiorama1",
        image: MuseumDiorama1,
        width: 200,
        height: 400,
        key: 24,
        title: "Museum diorama mural concept",
    },
    {
        imgAlt: "MuseumDiorama2",
        image: MuseumDiorama2,
        width: 200,
        height: 200,
        key: 25,
        title: "Museum diorama mural concept",
    },
    {
        imgAlt: "MuseumDiorama3",
        image: MuseumDiorama3,
        width: 200,
        height: 200,
        key: 26,
        title: "Museum diorama mural concept",
    },
    {
        imgAlt: "MuseumMural",
        image: MuseumMural,
        width: 200,
        height: 400,
        key: 27,
        title: "Museum diorama mural concept",
    },
    {
        imgAlt: "ScrollSignBorder",
        image: ScrollSignBorder,
        width: 200,
        height: 200,
        key: 28,
        title: "Scroll rendering for sign border",
    },
    {
        imgAlt: "SpanishResturantMural",
        image: SpanishResturantMural,
        width: 200,
        height: 400,
        key: 29,
        title: "Spanish restaurant mural concept",
    },
    {
        imgAlt: "TacoStandMenu",
        image: TacoStandMenu,
        width: 200,
        height: 400,
        key: 30,
        title: "Taco stand menu illustration",
    },
    {
        imgAlt: "UnderwaterChildrensMeseum",
        image: UnderwaterChildrensMeseum,
        width: 200,
        height: 400,
        key: 31,
        title: "Underwater childrens museum mural",
    },
    {
        imgAlt: "YMCAMural",
        image: YMCAMural,
        width: 200,
        height: 200,
        key: 32,
        title: "YMCA community center mural concept",
        isFeatured: true,
    },
    {
        imgAlt: "BreweryTankMural",
        image: BreweryTankMural2,
        width: 200,
        height: 400,
        key: 33,
        title: "Brewery tank mural rendering",
    },
    {
        imgAlt: "BrushcanLogoConcept",
        image: BrushcanLogoConcept1,
        width: 200,
        height: 200,
        key: 34,
        title: "Layout for shop logo",
    },
    {
        imgAlt: "ChildrensHospitalMural",
        image: ChildrensHospitalMural1,
        width: 200,
        height: 200,
        key: 35,
        title: "Children hospital mural concept",
    },
    {
        imgAlt: "ChildrensMuseumMural",
        image: ChildrensMuseumMural1,
        width: 200,
        height: 400,
        key: 36,
        title: "Childrens museum mural",
    },
    {
        imgAlt: "HerbalCompany",
        image: HerbalCompany2,
        width: 200,
        height: 400,
        key: 37,
        title: "Concept herbal medicine company",
        isFeatured: true,
    },
    {
        imgAlt: "HotelLobby",
        image: HotelLobby1,
        width: 200,
        height: 400,
        key: 38,
        title: "Color rendering for hotel lobby map mural 2016",
        // isFeatured: true,
    },
    {
        imgAlt: "MexicanResturant",
        image: MexicanResturant1,
        width: 200,
        height: 200,
        key: 39,
        title: "Mexican restaurant concepts",
        isFeatured: true,
    },
    {
        imgAlt: "OutdoorOufitters",
        image: OutdoorOufitters2,
        width: 200,
        height: 400,
        key: 40,
        title: "Outdoor outfitter concepts",
        isFeatured: true,
    },
];


// Galleries in order
export const AlbumCoverGallery = [
    {
        src: AlbumCover,
        key: 0,
        alt: "AlbumCover",
        caption: "Album cover concept sketch 2020"
    },
];

export const BBQMenuGallery = [
    {
        src: BBQMenu,
        key: 0,
        alt: "bbq_restaurant_menu_illustration_2015",
        caption: "Bbq restaurant menu illustration 2015"
    },
];
export const CarnivalBBQ1Gallery = [
    {
        src: CarnivalBBQ1,
        key: 0,
        alt: "carnival style rendering for bbq restaurant 2016",
        caption: "Carnival style rendering for bbq restaurant 2016"
    },
];
export const CarnivalBBQ2Gallery = [
    {
        src: CarnivalBBQ2,
        key: 0,
        alt: "carnival style rendering for bbq restaurant 2016",
        caption: "Carnival style rendering for bbq restaurant 2016"
    },
];
export const ToyStoreGallery = [
    {
        src: ToyStore,
        key: 0,
        alt: "concept_for_toy_store_mural_of_vintage_toys_2012",
        caption: "Concept for toy store mural of vintage toys 2012"
    },
];
export const VintagePosterGallery = [
    {
        src: VintagePoster,
        key: 0,
        alt: "concept for vintage poster style mural",
        caption: "Concept for vintage poster style mural 2018"
    },
];
export const BreweryMuralGallery = [
    {
        src: BreweryMural,
        key: 0,
        alt: "concept_sketch_for_brewery_mural_2017",
        caption: "Concept sketch for brewery mural 2017"
    },
];
export const WarehouseStudioGallery = [
    {
        alt: "concepts_for_artist_warehouse_studios_sign_2019",
        src: WarehouseStudio,
        key: 0,
        caption: "Concepts for artist warehouse studios sign 2019",
    },
];
export const DwntChildrensGallery = [
    {
        alt: "downtown_childrens_mural_2003",
        src: DwntChildrens,
        key: 0,
        caption: "Downtown childrens mural 2003",
    },
];
export const ExteriorGhostGallery = [
    {
        alt: "Exterior ghost sign style layout 2019",
        src: ExteriorGhostSign,
        key: 0,
        caption: "Exterior ghost sign style layout 2019",
    },
];
export const HunterBanksGallery = [
    {
        alt: "final_rendering_for_hunter_banks_mural_2011",
        src: HunterBanks,
        key: 0,
        caption: "Final rendering for hunter banks mural 2011",
    },
];
export const GardenStoreTruckGallery = [
    {
        alt: "garden_store_delivery_truck_graphic_concepts_2020",
        src: GardenStoreTruck,
        key: 0,
        caption: "Garden store delivery truck graphic concepts 2020",
    },
];
export const BakerySignGallery = [
    {
        alt: "hand_lettered_concept_for_bakery_sign_2020",
        src: BakerySign,
        key: 0,
        caption: "Hand lettered concept for bakery sign 2020",
    },
];
export const VIntageDeliverTruckGallery = [
    {
        alt: "hand_lettered_layout_for_vintage_delivery_truck",
        src: VintageDeliveryTruck,
        key: 0,
        caption: "Hand lettered layout for vintage delivery truck",
    },
];
export const BrushCaseGallery = [
    {
        alt: "hand_lettered_layout_sketch_for_brush_case_2020",
        src: BrushCase,
        key: 0,
        caption: "Hand lettered layout sketch for brush case",
    },
];
export const OfficeBuildingMuralGallery = [
    {
        alt: "OfficeBuildingMural",
        src: OfficeBuildingMural,
        key: 0,
        caption: "Hand lettered mural concept for historic office building 2019",
    },
];
export const ResturantMenuGallery = [
    {
        alt: "ResturantMenu",
        src: ResturantMenu,
        key: 0,
        caption: "Hand lettered restaurant menu background 2018",
    },
];
export const HotelLobbyMuralGallery = [
    {
        alt: "HotelLobbyMural",
        src: HotelLobbyMural,
        key: 0,
        caption: "Hotel lobby mural initial concept 2020",
    },
];
export const DistilleryMural1Gallery = [
    {
        alt: "DistilleryMural1",
        src: DistilleryMural1,
        key: 0,
        caption: "Initial concept for distillery mural 2018",
    },
];
export const DistilleryMural2Gallery = [
    {
        alt: "DistilleryMural2",
        src: DistilleryMural2,
        key: 0,
        caption: "Initial concept for distillery mural letterhead 2018",
    },
];
export const PosterArtRetrospectiveGallery = [
    {
        alt: "PosterArtRetrospective",
        src: PosterArtRetrospective,
        key: 0,
        caption: "Initial concept for poster art of my retrospective show 2017",
    },
];
export const ItalianRestMuralGallery = [
    {
        alt: "ItalianRestMural",
        src: ItalianRestMural,
        key: 0,
        caption: "Italian restaurant mural rendering 2003",
    },
];
export const LibraryMuralGallery = [
    {
        alt: "LibraryMural",
        src: LibraryMural,
        key: 0,
        caption: "Library mural concept 2016",
    },
];
export const BBQMuralGallery = [
    {
        alt: "BBQMural",
        src: BBQMural,
        key: 0,
        caption: "Mural concept for bbq restaurant classic cartoon style 2017",
    },
];
export const MuseumDiorama1Gallery = [
    {
        alt: "MuseumDiorama1",
        src: MuseumDiorama1,
        key: 0,
        caption: "Museum diorama mural concept 2001",
    },
];
export const MuseumDiorama2Gallery = [
    {
        alt: "MuseumDiorama2",
        src: MuseumDiorama2,
        key: 0,
        caption: "Museum diorama mural concept 2002",
    },
];
export const MuseumDiorama3Gallery = [
    {
        alt: "MuseumDiorama3",
        src: MuseumDiorama3,
        key: 0,
        caption: "Museum diorama mural concept 2007",
    },
];
export const MuseumMuralGallery = [
    {
        alt: "MuseumMural",
        src: MuseumMural,
        key: 0,
        caption: "Museum diorama mural concept 2008",
    },
];
export const ScrollSignBorderGallery = [
    {
        alt: "ScrollSignBorder",
        src: ScrollSignBorder,
        key: 0,
        caption: "Scroll rendering for sign border 2015",
    },
];
export const SpanishResturantMuralGallery = [
    {
        alt: "SpanishResturantMural",
        src: SpanishResturantMural,
        key: 0,
        caption: "Spanish restaurant initial mural concept 2018",
    },
];
export const TacoStandMenuGallery = [
    {
        alt: "TacoStandMenu",
        src: TacoStandMenu,
        key: 0,
        caption: "Taco stand menu illustration 2017",
    },
];
export const UnderwaterChildrensMeseumGallery = [
    {
        alt: "UnderwaterChildrensMeseum",
        src: UnderwaterChildrensMeseum,
        key: 0,
        caption: "Underwater childrens museum mural rendering 2005",
    },
];
export const YMCAMuralGallery = [
    {
        alt: "YMCAMural",
        src: YMCAMural,
        key: 0,
        caption: "YMCA community center mural concept 2018",
    },
];
export const BreweryTankMuralGallery = [
    {
        alt: "BreweryTankMural1",
        src: BreweryTankMural1,
        key: 0,
        caption: "Brewery tank mural rendering 2007",
    },
    {
        alt: "BreweryTankMural2",
        src: BreweryTankMural2,
        key: 1,
        caption: "Brewery tank mural rendering 2007",
    },
    {
        alt: "BreweryTankMural3",
        src: BreweryTankMural3,
        key: 2,
        caption: "Brewery tank mural rendering 2007",
    },
];
export const BrushcanLogoConceptGallery = [
    {
        alt: "BrushcanLogoConcept1",
        src: BrushcanLogoConcept1,
        key: 0,
        caption: "Initial hand lettered layout for shop logo 2017",
    },
    {
        alt: "BrushcanLogoConcept2",
        src: BrushcanLogoConcept2,
        key: 1,
        caption: "Initial hand lettered layout for shop logo 2017",
    },
];
export const ChildrensHospitalMuralGallery = [
    {
        alt: "ChildrensHospitalMural1",
        src: ChildrensHospitalMural1,
        key: 0,
        caption: "Children hospital mural concept 2003",
    },
    {
        alt: "ChildrensHospitalMural2",
        src: ChildrensHospitalMural2,
        key: 1,
        caption: "Children hospital mural concept 2003",
    },
];
export const ChildrensMuseumMuralMuralGallery = [
    {
        alt: "ChildrensMuseumMural1",
        src: ChildrensMuseumMural1,
        key: 0,
        caption: "Childrens museum mural baby dino entry sign rendering classic cartoon style",
    },
    {
        alt: "ChildrensMuseumMural2",
        src: ChildrensMuseumMural2,
        key: 1,
        caption: "Childrens museum mural baby dino rendering classic cartoon style",
    },
    {
        alt: "ChildrensMuseumMural3",
        src: ChildrensMuseumMural3,
        key: 2,
        caption: "Childrens museum mural rendering classic cartoon style",
    },
];
export const HerbalCompanyGallery = [
    {
        alt: "HerbalCompany1",
        src: HerbalCompany1,
        key: 0,
        caption: "Concept for lobby mural at herbal medicine company 2019",
    },
    {
        alt: "HerbalCompany2",
        src: HerbalCompany2,
        key: 1,
        caption: "Concept for lobby mural at herbal medicine company 2019",
    },
    {
        alt: "HerbalCompany3",
        src: HerbalCompany3,
        key: 2,
        caption: "Concept for lobby mural at herbal medicine company 2019",
    },
    {
        alt: "HerbalCompany4",
        src: HerbalCompany4,
        key: 3,
        caption: "Concept for lobby mural at herbal medicine company 2019",
    },
];
export const HotelLobbyGallery = [
    {
        alt: "HotelLobby1",
        src: HotelLobby1,
        key: 0,
        caption: "Color rendering for hotel lobby map mural 2016",
    },
    {
        alt: "HotelLobby2",
        src: HotelLobby2,
        key: 1,
        caption: "Color rendering for hotel lobby map mural detail 2016",
    },
];
export const MexicanResturantGallery = [
    {
        alt: "MexicanResturant1",
        src: MexicanResturant1,
        key: 0,
        caption: "Mexican restaurant menu illustration concepts 2017",
    },
    {
        alt: "MexicanResturant2",
        src: MexicanResturant2,
        key: 1,
        caption: "Mexican restaurant mural concept classic cartoon style 2017",
    },
];
export const OutdoorOufittersGallery = [
    {
        alt: "OutdoorOufitters1",
        src: OutdoorOufitters1,
        key: 0,
        caption: "Outdoor outfitter background for store mural 2013",
    },
    {
        alt: "OutdoorOufitters2",
        src: OutdoorOufitters2,
        key: 1,
        caption: "Outdoor outfitter multiple concepts for store mural 2013",
    },
];
