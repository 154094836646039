/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import BrushcanLogo from "assets/img/BrushcanLogo.webp"
// styles
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
// sections for this page
import SectionProfile from "./Sections/SectionProfile.js";
import SectionLinkPics from "./Sections/SectionLinkPics.js";
import SectionContact from "./Sections/SectionContact.js";
//ICons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
  const [checked, setChecked] = React.useState(true)

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <Header
        color="info"
        brand="Brushcan"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        {...rest}
      />
      <Parallax image={require("assets/img/brushcanImg/20160212_Rankin_Mural_0134.jpg")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ margin: "auto", width: "80%" }}>
                {/* <h1 className={classes.title}>
                  Brushcan Custom Murals and Design
                </h1> */}
                <img src={BrushcanLogo} style={{width: "100%"}}/>
                <br />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{ paddingTop: "2.5em", paddingBottom: "1em", margin: 'auto' }}>
          <div style={{ textAlign: "center", width: '90%', color: "#f7f6f4", margin: 'auto', paddingRight: "1em", paddingLeft: "1em", paddingBottom: "1em" }}
            className={classes.mainRaised}>
            <div style={{ color: "#9cc0b9", paddingTop: "1em", borderStyle: "none none solid none", borderColor: "#3C4858" }}>
              <h2><b>Welcome to the home of high quality custom murals and hand painted signs for over 20 years.</b></h2>
            </div>
            <p style={{ paddingTop: "1em" }}>
              <div style={{ margin: 'auto', fontSize: "large" }}>
                Offering professional design from concept to completion.
                The one time cost of a Brushcan mural or hand painted sign will attract attention to your
                location for decades to come.<br /><u>There is no better investment to show the pride and dedication
                you have in your business.</u>
              </div>
            </p>
            <br />
          </div>
        </div>
        <div className={classes.container}>
          <br />
          <SectionLinkPics />
          {/* <br/><br/> */}
          <div style={{ paddingTop: "1em", paddingBottom: "2em", paddingTop: "4em", margin: 'auto' }}>
            <div style={{ textAlign: "center", width: '90%', color: "#f7f6f4", margin: 'auto', paddingRight: "1em", paddingLeft: "1em" }}
              className={classes.mainRaised}>
              <div style={{ color: "#9cc0b9", paddingTop: "1em", borderStyle: "none none solid none", borderColor: "#3C4858" }}>
                <h2><b>The Process</b></h2>
              </div>
              <p style={{ paddingTop: "1em", paddingBottom: "2em" }}>
                <div style={{ margin: 'auto', width: '90%', fontSize: "large" }}>
                  We'll meet to discuss your interest in mural or sign. Whether you already have an idea that you want developed
                  or you are coming  with a clean slate,  I look forward to creating a custom concept catered specifically for you.
                  No need for intimidation, I am open to your ideas, and enjoy the diversity and original concepts that clients bring
                  the table. I'll take the information and create designs that accomplish the goal we established together.<br /><br />
                  Location, function, style, placement, architecture and budget are considerations that fit into the final design.
                  Discussing the designs with you and finalize the idea that you like.  Projects can be painted on site or in the studio
                  and applied to the wall. Anything is possible.<br /><br />
                  At Brushcan custom murals, I take great pride in my work and will provide you with the absolute best products in the
                  industry, proper prep of the surface, traditional painting, highest quality primer, paints and final varnish coats.
                </div>
              </p>
            </div>
          </div>
          <SectionProfile />
          {/* <br /><br /> */}
          <SectionContact />
          <a id="contactForm" />
        </div>
        <br />
      </div>
      <Footer
        content={
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <div>
                  <div>
                    <a href="https://www.facebook.com/brushcan.copm/" target="_blank">
                    <FontAwesomeIcon icon={faFacebookSquare} style={{marginTop: ".3em", color: "#4267B2"}} size="2x"/> Facebook
                    </a>
                    <a href="https://www.instagram.com/brushcan" target="_blank">
                    <FontAwesomeIcon icon={faInstagramSquare} style={{marginLeft: "2em", marginTop: ".3em", color: "#C13584"}} size="2x"/> Instagram
                    </a>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <div style={{ marginTop: "1.2em", color: "black"}}>
                  &copy; {1900 + new Date().getYear()} {" "}
                  <a
                    href="https://www.overpassdev.com"
                    target="_blank"
                  >
                    Overpass Software Development
              </a>{" "}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        }
      />
    </div>
  );
}
