import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  snackBar: {
    color: theme.palette.common.white,
  },
}));

export default function SimpleSnackbar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
      >
        {props.type === "success" ? 
        <Alert onClose={handleClose} severity="success">
        {props.message}
        </Alert>
        : <Alert onClose={handleClose} severity="error">
        {props.message}
        </Alert>}
      </Snackbar>
    </div>
  );
}