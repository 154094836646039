//Real
import BrushcanTrailer from "assets/img/signs/Brushcantrailerhanddesignedandlettered2017.webp";
import BlackTipShark from "assets/img/signs/Classicreliefshadowletteringona5BlackTipSharkCrookedCreekHollerOutfittersAsheville2020.webp";
import DripCoffee from "assets/img/signs/DripCoffee.webp";
import ElemetarySchool from "assets/img/signs/Elementaryschoolcarnivalsigns12signsquicklyhandletteredonsite2008.webp";
import AwardSign from "assets/img/signs/Handletteredawardsign2019Enamelonpanel.webp";
import HerbanBaker from "assets/img/signs/HerbanBakersignAshevilleNCEnamelonboard2014.webp";
import Pickled from "assets/img/signs/PlesantlyPickledcanningcompanysign2012Enamelonpanel.webp";
import RegenStation from "assets/img/signs/RegenerationStationUpcyclingstoreAshevilleNCsign2010Acryliconpanel.webp";
import ResaleCompany from "assets/img/signs/ResalecompanysignwsubtleGratefulDeadandLSDreference2011Enamelonboard.webp";
import ToyStore from "assets/img/signs/ToystoresandwichboardsignAshevilleNC2006Enamelonboard.webp";
import VintageSign2004 from "assets/img/signs/Vintagesignreproduction2004Acryliconwoodpanel.webp";
import VintageSign2007 from "assets/img/signs/Vintagesignreproductionsign2007Enamelondrumlid_painted rust.webp";

//Folders
import Foundation1 from "assets/img/signs/FoundationWarehouse/FoundationWarehouseGalleriespictorialsign2019.webp";
import Foundation2 from "assets/img/signs/FoundationWarehouse/FoundationWarehouseGalleriespictorialsigndetail2019.webp";
//
import JettieRae1 from "assets/img/signs/JettieRaeshandletteredwindows/WindowletteringdetailJettieRaesAsheville2020.webp"
import JettieRae2 from "assets/img/signs/JettieRaeshandletteredwindows/WindowletteringJettieRaesAsheville2020.webp"
import JettieRae3 from "assets/img/signs/JettieRaeshandletteredwindows/WindowletteringprogressJettieRaesAsheville2020.webp"
//
import Chemist1 from "assets/img/signs/ChemistAntidoteLounge/ChemistFront.webp"
import Chemist2 from "assets/img/signs/ChemistAntidoteLounge/ChemistSpirit.webp"
import Chemist3 from "assets/img/signs/ChemistAntidoteLounge/ChemistTop.webp"
import Chemist4 from "assets/img/signs/ChemistAntidoteLounge/IMG_20180129_124221.webp"
import Chemist5 from "assets/img/signs/ChemistAntidoteLounge/Sundries1.webp"
//
import Hemp1 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarms1newstorefront1downtownAsheville2020Watergildedandhandlettered.webp"
import Hemp2 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarms2newstorefrontdowntownAsheville2020Watergildedandhandlettered.webp"
import Hemp3 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarms2sidedbladesign2018.webp"
import Hemp4 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarms3newstorefront1downtownAsheville2020Watergildedandhandlettered.webp"
import Hemp5 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarmsgoldleafandhandletteredantiquemirror2018.webp"
import Hemp6 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarmsSandwichboardsignfinished2019.webp"
import Hemp7 from "assets/img/signs/AshevilleHempFarmsmultiplesignpackage/AshevilleHempFarmsSandwichboardsigninprogress2019.webp"

//Gallery specific front page images
export const SignsGalleryFrontImgs = [
    {
        imgAlt: "Brushcantrailerhanddesignedandlettered2017",
        image: BrushcanTrailer,
        width: 200,
        height: 400,
        key: 0,
        title: "Brushcan Trailer",
    },
    {
        imgAlt: "Classicreliefshadowletteringona5BlackTipSharkCrookedCreekHollerOutfittersAsheville2020",
        image: BlackTipShark,
        width: 200,
        height: 200,
        key: 1,
        title: "Classic relief shadow letters black tip shark",
        isFeatured: true,
    },
    {
        imgAlt: "DripCoffee",
        image: DripCoffee,
        width: 200,
        height: 400,
        key: 2,
        title: "Drip Coffee Sign",
    },
    {
        imgAlt: "Elementaryschoolcarnivalsigns12signsquicklyhandletteredonsite2008",
        image: ElemetarySchool,
        width: 200,
        height: 200,
        key: 3,
        title: "Elementary school carnival signs",
    },
    {
        imgAlt: "Handletteredawardsign2019Enamelonpanel",
        image: AwardSign,
        width: 200,
        height: 400,
        key: 4,
        title: "Hand lettered award sign",
    },
    {
        imgAlt: "HerbanBakersignAshevilleNCEnamelonboard2014",
        image: HerbanBaker,
        width: 200,
        height: 400,
        key: 5,
        title: "Herban Baker sign",
    },
    {
        imgAlt: "PlesantlyPickledcanningcompanysign2012Enamelonpanel",
        image: Pickled,
        width: 200,
        height: 200,
        key: 6,
        title: "Plesantly Pickled canning company sign",
    },
    {
        imgAlt: "RegenerationStationUpcyclingstoreAshevilleNCsign2010Acryliconpanel",
        image: RegenStation,
        width: 200,
        height: 400,
        key: 7,
        title: "Regeneration Station Upcycling store",
        isFeatured: true,
    },
    {
        imgAlt: "ResalecompanysignwsubtleGratefulDeadandLSDreference2011Enamelonboard",
        image: ResaleCompany,
        width: 200,
        height: 400,
        key: 8,
        title: "Resale company sign",
        isFeatured: true,
    },
    {
        imgAlt: "ToystoresandwichboardsignAshevilleNC2006Enamelonboard",
        image: ToyStore,
        width: 200,
        height: 600,
        key: 9,
        title: "Toy store sandwich board sign",
    },
    {
        imgAlt: "Vintagesignreproduction2004Acryliconwoodpanel",
        image: VintageSign2004,
        width: 200,
        height: 200,
        key: 10,
        title: "Vintage sign reproduction painted rust 2004",
    },
    {
        imgAlt: "Vintagesignreproductionsign2007Enamelondrumlid_paintedrust",
        image: VintageSign2007,
        width: 200,
        height: 400,
        key: 11,
        title: "Vintage sign reproduction painted rust 2007",
    },
    {
        imgAlt: "AshevilleHempFarmsnewstorefrontdowntownAsheville2020Watergildedandhandlettered",
        image: Hemp2,
        width: 200,
        height: 400,
        key: 12,
        title: "Asheville Hemp Farms Store Front",
        // isFeatured: true,
    },
    {
        imgAlt: "ChemistFront",
        image: Chemist1,
        width: 200,
        height: 400,
        key: 13,
        title: "Chemist Spirits Signs",
        isFeatured: true,
    },
    {
        imgAlt: "FoundationWarehouseGalleriespictorialsign2019",
        image: Foundation1,
        width: 200,
        height: 400,
        key: 14,
        title: "Foundation Warehouse sign",
    },
    {
        imgAlt: "WindowletteringJettieRaesAsheville2020",
        image: JettieRae1,
        width: 200,
        height: 400,
        key: 15,
        title: "Jettie Raes Window lettering",
    },
];

// Galleries in order
export const BrushcanTrailerGallery = [
    {
        src: BrushcanTrailer,
        width: 3,
        height: 2,
        key: 0,
        alt: "Brushcantrailerhanddesignedandlettered2017",
        caption: "Brushcan trailer hand designed and lettered 2017"
    },
];
export const BlackTipGallery = [
    {
        src: BlackTipShark,
        width: 3,
        height: 2,
        key: 0,
        alt: "Classicreliefshadowletteringona5BlackTipSharkCrookedCreekHollerOutfittersAsheville2020",
        caption: "Classic relief shadow letter Black Tip Shark Crooked Creek Holler Outfitters Asheville 2020"
    },
];
export const DripCoffeeGallery = [
    {
        src: DripCoffee,
        width: 3,
        height: 2,
        key: 0,
        alt: "DripCoffee",
        caption: "Drip Coffee sign"
    },
];
export const ElemetarySchoolGallery = [
    {
        src: ElemetarySchool,
        width: 3,
        height: 2,
        key: 0,
        alt: "Elementaryschoolcarnivalsigns12signsquicklyhandletteredonsite2008",
        caption: "Elementary school carnival signs 12 signs quickly hand lettered on-site 2008"
    },
];
export const AwardSignGallery = [
    {
        src: AwardSign,
        width: 3,
        height: 2,
        key: 0,
        alt: "Handletteredawardsign2019Enamelonpanel",
        caption: "Hand lettered award sign 2019 Enamel on panel"
    },
];
export const HerbanBakerGallery = [
    {
        src: HerbanBaker,
        width: 3,
        height: 2,
        key: 0,
        alt: "HerbanBakersignAshevilleNCEnamelonboard2014",
        caption: "Herban Baker sign Asheville NC Enamel on board 2014"
    },
];
export const PickledGallery = [
    {
        src: Pickled,
        width: 3,
        height: 2,
        key: 0,
        alt: "PlesantlyPickledcanningcompanysign2012Enamelonpanel",
        caption: "Plesantly Pickled canning company sign 2012 Enamel on panel"
    },
];
export const RegenStationGallery = [
    {
        src: RegenStation,
        width: 3,
        height: 2,
        key: 0,
        alt: "RegenerationStationUpcyclingstoreAshevilleNCsign2010Acryliconpanel",
        caption: "Regeneration Station Upcycling store Asheville NC sign 2010 Acrylic onpanel"
    },
];
export const ResaleCompanyGallery = [
    {
        src: ResaleCompany,
        width: 3,
        height: 2,
        key: 0,
        alt: "ResalecompanysignwsubtleGratefulDeadandLSDreference2011Enamelonboard",
        caption: "Resale company sign with subtle Grateful Dead and LSD reference 2011 Enamel on board"
    },
];
export const ToyStoreGallery = [
    {
        src: ToyStore,
        width: 3,
        height: 2,
        key: 0,
        alt: "ToystoresandwichboardsignAshevilleNC2006Enamelonboard",
        caption: "Toy store sandwich board sign Asheville NC 2006 Enamel on board"
    },
];
export const VintageSign2004Gallery = [
    {
        src: VintageSign2004,
        width: 3,
        height: 2,
        key: 0,
        alt: "Vintagesignreproduction2004Acryliconwoodpanel",
        caption: "Vintage sign reproduction 2004 Acrylic on wood panel"
    },
];
export const VintageSign2007Gallery = [
    {
        src: VintageSign2007,
        width: 3,
        height: 2,
        key: 0,
        alt: "Vintagesignreproductionsign2007Enamelondrumlid_paintedrust",
        caption: "Vintage sign reproduction sign 2007 Enamel on drumlid painted rust"
    },
];
export const HempFarmGallery = [
    {
        src: Hemp1,
        width: 3,
        height: 2,
        key: 0,
        alt: "AshevilleHempFarms2newstorefrontdowntownAsheville2020Watergildedandhandlettered",
        caption: "Asheville Hemp Farms 1 new store front downtown Asheville 2020 Water gilded and hand lettered"
    } ,
    {
        src: Hemp2,
        width: 3,
        height: 2,
        key: 1,
        alt: "AshevilleHempFarms2newstorefrontdowntownAsheville2020Watergildedandhandlettered",
        caption: "Asheville Hemp Farms 2 new store front downtown Asheville 2020 Water gilded and hand lettered"
    },
    {
        src: Hemp3,
        width: 3,
        height: 2,
        key: 2,
        alt: "AshevilleHempFarms2sidedbladesign2018",
        caption: "Asheville Hemp Farms 2 sided blade sign 2018"
    },
    {
        src: Hemp4,
        width: 3,
        height: 2,
        key: 3,
        alt: "AshevilleHempFarms3newstorefront1downtownAsheville2020Watergildedandhandlettered",
        caption: "Asheville Hemp Farms 3 new store front downtown Asheville 2020 Water gilded and hand lettered"
    },
    {
        src: Hemp5,
        width: 3,
        height: 2,
        key: 4,
        alt: "AshevilleHempFarmsgoldleafandhandletteredantiquemirror2018",
        caption: "Asheville Hemp Farms goldleaf and hand lettered antique mirror 2018"
    },
    {
        src: Hemp6,
        width: 3,
        height: 2,
        key: 5,
        alt: "AshevilleHempFarmsSandwichboardsignfinished2019",
        caption: "Asheville Hemp Farms Sandwich board sign finished 2019"
    },
    {
        src: Hemp7,
        width: 3,
        height: 2,
        key: 6,
        alt: "AshevilleHempFarmsSandwichboardsigninprogress2019",
        caption: "Asheville Hemp Farms Sandwich board sign in progress 2019"
    },
];
export const ChemistGallery = [
    {
        src: Chemist1,
        width: 3,
        height: 2,
        key: 0,
        alt: "ChemistFront",
        caption: "Chemist Spirits Front"
    } ,
    {
        src: Chemist2,
        width: 3,
        height: 2,
        key: 1,
        alt: "ChemistSpirit",
        caption: "Chemist Spirits Sign"
    },
    {
        src: Chemist3,
        width: 3,
        height: 2,
        key: 2,
        alt: "ChemistSpiritsAntidoteTop",
        caption: "Chemist Spirits Antidote Top"
    },
    {
        src: Chemist4,
        width: 3,
        height: 2,
        key: 3,
        alt: "ChemistHandLetteredSign",
        caption: "Chemist Hand Lettered Sign"
    },
    {
        src: Chemist5,
        width: 3,
        height: 2,
        key: 4,
        alt: "ChemistMecantileWindowSign",
        caption: "Chemist Mecantile Window Sign"
    },
];
export const FoundationGallery = [
    {
        src: Foundation1,
        width: 3,
        height: 2,
        key: 0,
        alt: "FoundationWarehouseGalleriespictorialsign2019",
        caption: "Foundation Warehouse Galleries pictorial sign 2019"
    } ,
    {
        src: Foundation2,
        width: 3,
        height: 2,
        key: 1,
        alt: "FoundationWarehouseGalleriespictorialsindetail2019",
        caption: "Foundation Warehouse Galleries pictorials in detail 2019"
    },
];
export const JettieRaeGallery = [
    {
        src: JettieRae1,
        width: 3,
        height: 2,
        key: 0,
        alt: "WindowletteringdetailJettieRaesAsheville2020",
        caption: "Window lettering detail Jettie Raes Asheville 2020"
    } ,
    {
        src: JettieRae2,
        width: 3,
        height: 2,
        key: 1,
        alt: "WindowletteringJettieRaesAsheville2020",
        caption: "Window lettering Jettie Raes Asheville 2020"
    },
    {
        src: JettieRae3,
        width: 3,
        height: 2,
        key: 1,
        alt: "WindowletteringprogressJettieRaesAsheville2020",
        caption: "Window lettering progress Jettie Raes Asheville 2020"
    },
];
