/*eslint-disable*/ import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
//ICons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

// Gallerys
import {
  GraphicDesignGalleryFrontImgs,
  OrangePeel2010Gallery,
  OrangePeel2009Gallery,
  SummerCampGallery,
  TriumphGallery,
  YonderMountain2010Gallery,
  YonderMountain2012Gallery,
  YonderMountain2011Gallery,
  YonderMountain2009Gallery,
  YonderMountain2013Gallery,
  FreshPreservesGallery,
  RicHickeyGallery,
  LefOverGallery,
  DevotchkaGallery,
  BuncombeTurnpikeGallery,
  BlueWheelDriveGallery,
} from "../GraphicDesign/GDImages.js";

import galleryPageStyle from "assets/jss/material-kit-pro-react/views/galleryPageStyle.js";

// Sections for this page
import SectionGallery from "../Sections/SectionGallery.js";

const useStyles = makeStyles(galleryPageStyle);

export default function LandingPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const frontGallery = [...GraphicDesignGalleryFrontImgs];
  const galleryArray = [
    OrangePeel2010Gallery,
    OrangePeel2009Gallery,
    SummerCampGallery,
    TriumphGallery,
    YonderMountain2010Gallery,
    YonderMountain2012Gallery,
    YonderMountain2011Gallery,
    YonderMountain2009Gallery,
    YonderMountain2013Gallery,
    FreshPreservesGallery,
    RicHickeyGallery,
    LefOverGallery,
    DevotchkaGallery,
    BuncombeTurnpikeGallery,
    BlueWheelDriveGallery,
  ]
  const classes = useStyles();

  return (
    <div>
      <Header
        color="info"
        brand="Brushcan"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        {...rest}
      />
      <Parallax image={require("assets/img/graphicDesign/OrangePeeltshirtAshevilleNC2009.webp")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ textAlign: "center", color: "#f7f6f4" }}>
                <h1 style={ { fontWeight: "bold" }}>
                  Graphic Design
                </h1>
                <br />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "large", color: "#f7f6f4" }}>
                  <u>True traditional graphic design has always been my passion. From logos, products, posters and album covers</u>
                </p>
                <br />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {/* Show sections */}

          <SectionGallery frontGallery={frontGallery} galleryArray={galleryArray} />

        </div>
      </div>
      <Footer
        content={
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <div>
                  <div>
                    <a href="https://www.facebook.com/brushcan.copm/" target="_blank">
                    <FontAwesomeIcon icon={faFacebookSquare} style={{marginTop: ".3em", color: "#4267B2"}} size="2x"/> Facebook
                    </a>
                    <a href="https://www.instagram.com/brushcan" target="_blank">
                    <FontAwesomeIcon icon={faInstagramSquare} style={{marginLeft: "2em", marginTop: ".3em", color: "#C13584"}} size="2x"/> Instagram
                    </a>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <div style={{ marginTop: "1.2em", color: "black"}}>
                  &copy; {1900 + new Date().getYear()} {" "}
                  <a
                    href="https://www.overpassdev.com"
                    target="_blank"
                  >
                    Overpass Software Development
              </a>{" "}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        }
      />
    </div>
  );
}
