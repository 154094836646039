import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import SimpleReactLightbox from "simple-react-lightbox";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import ConceptArtPage from "views/GalleryPages/ConceptArt/ConceptArtPage.js";
import GraphicDesignPage from "views/GalleryPages/GraphicDesign/GraphicDesignPage.js";
import IllustrationPage from "views/GalleryPages/Illustration/IllustrationPage.js";
import LogoDesignPage from "views/GalleryPages/LogoDesign/LogoDesignPage.js";
import MuralsPage from "views/GalleryPages/Murals/MuralsPage.js";
import SignsPage from "views/GalleryPages/Signs/SignsPage.js";
import SpecialtyFinishesPage from "views/GalleryPages/SpecialtyFinishes/SpecialtyFinishesPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <SimpleReactLightbox>
      <Switch>
          <Route path="/error-page" component={ErrorPage} />
          {/* <Route path="/landing-page" component={LandingPage} /> */}
          <Route path="/concept-art" component={ConceptArtPage} />
          <Route path="/graphic-design" component={GraphicDesignPage} />
          <Route path="/illustration" component={IllustrationPage} />
          <Route path="/logo-design" component={LogoDesignPage} />
          <Route path="/murals" component={MuralsPage} />
          <Route path="/signs" component={SignsPage} />
          <Route path="/specialty-finishes" component={SpecialtyFinishesPage} />
          <Route path="/" component={LandingPage} />
      </Switch>
    </SimpleReactLightbox>
  </Router>,
  document.getElementById("root")
);
