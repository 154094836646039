import {
  container,
  title,
  main,
  mainRaised,
  mlAuto,
  description,
} from "assets/jss/material-kit-pro-react.js";

const contactUsStyle = {
  main,
  mainRaised,
  title,
  mlAuto,
  description,
  container: {
    ...container,
    maxWidth: "970px !important"
  },
  // title: {
  //   ...title,
  //   display: "inline-block",
  //   position: "relative",
  //   marginTop: "30px",
  //   minHeight: "32px",
  //   color: "whiteColor",
  //   textDecoration: "none"
  // },
  contactContent: {
    paddingBottom: "40px",
    paddingTop: "40px"
  },
  bigMap: {
    height: "55vh",
    maxHeight: "550px",
    width: "100%",
    display: "block"
  },
  info: {
    paddingBottom: "10px",
    paddingTop: 0
  },
  textCenter: {
    textAlign: "center !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  position: {
    position: "relative"
  },
  bottom: {
    // position: "relative",
    // margin: "auto",
    textAlign: "center",
    // placeContent: "center",
    // display: "inline-block",
   
    // justifyContent: "center",
    // alignItems: "center",
  }
};

export default contactUsStyle;
