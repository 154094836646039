//Real
import AshevilleFarms from "assets/img/logoDesign/AshevilleFarmsbillheadstylelogo2019.webp";
import BlueSkySummerCamp from "assets/img/logoDesign/BlueSkysummercamplogo2006.webp";
import BonfireBBQ from "assets/img/logoDesign/BonfireBBQlogo2017.webp";
import Brushcan from "assets/img/logoDesign/Brushcanlogo2016.webp";
import RiverbendMalt from "assets/img/logoDesign/RiverbendMaltbillheadstylelogo2018.webp";
import RiverviewFarms from "assets/img/logoDesign/Riverviewfarmslogo1994.webp";
import RoyalWeTshirt from "assets/img/logoDesign/RoyalWetShirtartwork2004.webp";
import ShaolinYinYang from "assets/img/logoDesign/Shaolinyinyanglogo2012.webp";
import SisOfMercy from "assets/img/logoDesign/SistersofMercyWellbeelogo2012.webp";
import WAIF from "assets/img/logoDesign/WAIFlogoCintiOH1991.webp";
import WNCW from "assets/img/logoDesign/WNCWlocalcolorlogo2005.webp";
import ZeldaHotel from "assets/img/logoDesign/ZeldaHotellogo2005.webp";
import Dogcity from "assets/img/logoDesign/DogCitylogo2014.webp";
import EchoMountain from "assets/img/logoDesign/EchoMountainlogo2016.webp";
import HerbanBaker from "assets/img/logoDesign/Herbanbakerroundpt2.webp";
import PianoKeys from "assets/img/logoDesign/logopianokeybuildings.webp";
import MtAdams from "assets/img/logoDesign/MtAdamsBarandGrilllogo1992.webp";
//Folders
import PleasantlyPick from "assets/img/logoDesign/PleasantlyPickled/PleasantlyPickledlogo2015.webp";
import PleasantlyPickProg from "assets/img/logoDesign/PleasantlyPickled/PleasantlyPickledlabelprogressionsm.webp";
//
import OPTshirt09 from "assets/img/logoDesign/OrangePeelTShirt/OrangePeeltshirtart2009.webp";
import OPTshirt08 from "assets/img/logoDesign/OrangePeelTShirt/OrangePeeltshirtart2008.webp";
//
import Chemist from "assets/img/logoDesign/Chemist/Chemistbillheadstylelogo2018.webp";
import ChemistProgress from "assets/img/logoDesign/Chemist/Chemistbillheadstyledesignprogressionsm2018.webp";

//Gallery specific front page images
export const LogoDesignGalleryFrontImgs = [
    {
        imgAlt: "Asheville Farms Logo",
        image: AshevilleFarms,
        width: 200,
        height: 300,
        key: 0,
        title: "Asheville Farms Logo",
        isFeatured: true,
    },
    {
        imgAlt: "BlueSky Summer Camp Logo",
        image: BlueSkySummerCamp,
        width: 200,
        height: 200,
        key: 1,
        title: "BlueSky Summer Camp Logo",
    },
    {
        imgAlt: "Bonfire BBQ Logo",
        image: BonfireBBQ,
        width: 200,
        height: 400,
        key: 2,
        title: "Bonfire BBQ Logo",
        isFeatured: true,
    },
    {
        imgAlt: "Brushcan Logo",
        image: Brushcan,
        width: 200,
        height: 200,
        key: 3,
        title: "Brushcan Logo",
    },
    {
        imgAlt: "Chemist Logo",
        image: Chemist,
        width: 200,
        height: 200,
        key: 4,
        title: "Chemist Logo",
    },
    {
        imgAlt: "Dogcity Logo",
        image: Dogcity,
        width: 200,
        height: 600,
        key: 5,
        title: "Dogcity Logo",
        // isFeatured: true,
    },
    {
        imgAlt: "Echo Mountain Logo",
        image: EchoMountain,
        width: 200,
        height: 200,
        key: 6,
        title: "Echo Mountain Logo",
        isFeatured: true,
    },
    {
        imgAlt: "HerbanBaker Logo",
        image: HerbanBaker,
        width: 200,
        height: 200,
        key: 7,
        title: "Herban Baker Logo",
    },
    {
        imgAlt: "Piano Factory Lofts Logo",
        image: PianoKeys,
        width: 200,
        height: 600,
        key: 8,
        title: "Piano Factory Lofts Logo",
    },
    {
        imgAlt: "Mt. Adams Bar & Grill Logo",
        image: MtAdams,
        width: 200,
        height: 200,
        key: 9,
        title: "Mt. Adams Bar & Grill Logo",
    },
    {
        imgAlt: "Orange Peel T-shirt Logo",
        image: OPTshirt09,
        width: 200,
        height: 400,
        key: 10,
        title: "Orange Peel T-shirt Logo",
    },
    {
        imgAlt: "Pleasantly Pickled Logo",
        image: PleasantlyPick,
        width: 200,
        height: 400,
        key: 11,
        title: "Pleasantly Pickled Logo",
    },
    {
        imgAlt: "Riverbend Malt Logo",
        image: RiverbendMalt,
        width: 200,
        height: 200,
        key: 12,
        title: "Riverbend Malt Logo",
        isFeatured: true,
    },
    {
        imgAlt: "Riverview Farms Logo",
        image: RiverviewFarms,
        width: 200,
        height: 200,
        key: 13,
        title: "Riverview Farms Logo",
    },
    {
        imgAlt: "Royal We T-shirt Logo",
        image: RoyalWeTshirt,
        width: 200,
        height: 200,
        key: 14,
        title: "Royal We T-shirt Logo",
    },
    {
        imgAlt: "Shaolin Yin Yang Logo",
        image: ShaolinYinYang,
        width: 200,
        height: 400,
        key: 15,
        title: "Shaolin Kung-Fu Logo",
        // isFeatured: true,
    },
    {
        imgAlt: "Sisters Of Mercy Logo",
        image: SisOfMercy,
        width: 200,
        height: 400,
        key: 16,
        title: "Sisters Of Mercy Logo",
    },
    {
        imgAlt: "WAIF Radio Logo",
        image: WAIF,
        width: 200,
        height: 300,
        key: 17,
        title: "WAIF Radio Logo",
    },
    {
        imgAlt: "WNCW Logo",
        image: WNCW,
        width: 200,
        height: 300,
        key: 18,
        title: "WNCW Logo",
    },
    {
        imgAlt: "Zelda Hotel Logo",
        image: ZeldaHotel,
        width: 200,
        height: 200,
        key: 19,
        title: "Zelda Hotel Logo",
    },
];

// Galleries in order

export const AshevilleFarmsLogo = [
    {
        key: 0,
        src: AshevilleFarms,
        width: 1024,
        height: 'auto',
        alt: "",
        caption: "Asheville Farms Billhead Logo 2019",
    }
]

export const BlueSkySummerCampLogo = [
    {
        key: 0,
        src: BlueSkySummerCamp,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "BlueSky Summercamp Logo 2006",
    }
]

export const BonfireBBQLogo = [
    {
        key: 0,
        src: BonfireBBQ,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Bonfire BBQ Logo 2017",
    }
]

export const BrushcanLogo = [
    {
        key: 0,
        src: Brushcan,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Brushcan Logo 2016",
    }
]

export const ChemistLogo = [
    {
        key: 0,
        src: Chemist,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Chemist Logo 2016",
    },
    {
        key: 1,
        src: ChemistProgress,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Chemist Logo Progression 2016",
    }
]

export const DogcityLogo = [
    {
        key: 0,
        src: Dogcity,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Dogcity Logo 2014",
    }
]

export const EchoMountainLogo = [
    {
        key: 0,
        src: EchoMountain,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Echo Mountain Logo 2016",
    }
]

export const HerbanBakerLogo = [
    {
        key: 0,
        src: HerbanBaker,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Herban Baker Logo",
    }
]

export const PianoKeysLogo = [
    {
        key: 0,
        src: PianoKeys,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Piano Factory Lofts Logo 2005",
    }
]

export const MtAdamsLogo = [
    {
        key: 0,
        src: MtAdams,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Mt. Adams Bar & Grill Logo",
    }
]

export const OPTshirtLogos = [
    {
        key: 0,
        src: OPTshirt09,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Orange Peel T-shirt Logo 2009",
    },
    {
        key: 1,
        src: OPTshirt08,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Orange Peel T-shirt Logo 2008",
    }
]

export const PleasantlyPickLogos = [
    {
        key: 0,
        src: PleasantlyPick,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Pleasantly Pickled Logo 2015",
    },
    {
        key: 1,
        src: PleasantlyPickProg,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Pleasantly Pickled Logo Progress 2015",
    }
]

export const RiverbendMaltLogo = [
    {
        key: 0,
        src: RiverbendMalt,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Riverbend Malt Logo",
    }
]

export const RiverviewFarmsLogo = [
    {
        key: 0,
        src: RiverviewFarms,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Riverview Farms Logo",
    }
]

export const RoyalWeTshirtLogo = [
    {
        key: 0,
        src: RoyalWeTshirt,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Royal We T-shirt Logo",
    }
]

export const ShaolinYinYangLogo = [
    {
        key: 0,
        src: ShaolinYinYang,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Shaolin Yin Yang Logo",
    }
]

export const SisOfMercyLogo = [
    {
        key: 0,
        src: SisOfMercy,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Sisters Of Mercy Logo",
    }
]

export const WAIFLogo = [
    {
        key: 0,
        src: WAIF,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "WAIF Radio Logo",
    }
]

export const WNCWLogo = [
    {
        key: 0,
        src: WNCW,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "WNCW Logo",
    }
]

export const ZeldaHotelLogo = [
    {
        key: 0,
        src: ZeldaHotel,
        width: 1024,
        height: 'auto',
        alt: "ALT ONE",
        caption: "Zelda Hotel Logo",
    }
]
