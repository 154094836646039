import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Masonry from "../MasonryView.js"
import MyMasonryItem from "../MyMasonryItem.js"

export default class SectionGallery extends React.Component {
  constructor(frontGallery, galleryArray) {
    super(frontGallery, galleryArray);
    this.state = {
      items: this.props.frontGallery,
      hasMore: false,
      columnGutter: 10,
      columnWidth: 10,
      numColumns: this.RenderNumCol(),
      // parentWidth: this.props.parentWidth,
      isFetching: true,
      // columnAmount: 2,
    };
    window.addEventListener('resize', () => {
      this.setState({numColumns: this.RenderNumCol()})
      });
  }

  RenderNumCol() {
    if (window.innerWidth < 550) {
      return 1;
    }
    if (window.innerWidth > 550 && window.innerWidth < 1300) {
      return 2;
    }
    if (window.innerWidth > 1300 && window.innerWidth < 1800) {
      return 3;
    }
    if (window.innerWidth > 1800 && window.innerWidth < 2300) {
      return 4;
    }
    if (window.innerWidth > 2300) {
      return 5;
    }

  }

  //insert logic
  getMoreData() {
    return true;
  }

  fetch () {
    // update isLoading flag appropriately
    // const additionalData = this.getMoreData();
    // this.setState((prevState) => ({
    //   items: prevState.items.concat(additionalData.items),
    //   hasMore: additionalData.hasMore,
    // }))
  }

  getState = () => this.state

  shouldComponentUpdate(prevState, prevProps) {
    if (prevState.numColumns !== this.state.numColumns) {
      return true;
    }
    if (prevState.numColumns === this.state.numColumns) {
      return false;
    }
  }
  
  render() {
    
          if (!this.state.items) { return }
          //set galleryArray here!!!!!
          const frontGallery = this.props.frontGallery;

          // console.log(this.state.parentWidth)

    return (
      <div style={{overflow: 'hidden'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <br/>
            <Masonry
              items={frontGallery}
              itemComponent={(props) => (<MyMasonryItem {...props}
              />)}
              alignCenter={true}
              containerClassName={'galleryPageStyle.container'}
              layoutClassName={''}
              pageClassName={''}
              loadingElement={<span>Loading...</span>}
              columnWidth={this.state.columnWidth}
              numColumns={this.state.numColumns}
              columnGutter={this.state.columnGutter}
              hasMore={false}
              isLoading={this.state.isFetching}
              // onInfiniteLoad={this.fetch}
              getState={this.getState}
              galleryArray={this.props.galleryArray}
            />
            <br/>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}