import AWRoot from "assets/img/illustration/AWRootbeerStandHuntingtonIN2005PencilandCoffeeonvintagepaper.webp";
import AbandonChurch from "assets/img/illustration/AbandondedChurchMarshallNC2012Pencilonvintagepaper.webp";
import AmazonFerry from "assets/img/illustration/AmazonFerryBoat2006PencilandCoffeeonvintagepaper.webp";
import GrainProcessing from "assets/img/illustration/GrainProcessingHendersonvilleNC2006PencilandCoffeeonvintagepaper.webp";
import GrainStorage from "assets/img/illustration/GrainStorageGrabillIN2005PencilandCoffeeonvintagepaper.webp";
import FarmHouse from "assets/img/illustration/ItalianatefarmhouseDecaturIN2005PencilandCoffeeonvintagepaper.webp";
import TownHouse from "assets/img/illustration/ItalianatetownhouseCovingtonKY2005PencilandCoffeeonvintagepaper.webp";
import JacksonBldg from "assets/img/illustration/JacksonBldgAshevilleNC2006PencilandWatercoloronillustrationboard.webp";
import OfficeBldg from "assets/img/illustration/OfficeBuildingLexingtonCollegeStAshevilleNC2006PencilandCoffeeonvintagepaper.webp";
import Tram from "assets/img/illustration/ParkinglottramDollywoodPigeonForgeTN2006Gouacheonvintagenotecard.webp";
import Viaduct from "assets/img/illustration/ViaductMcDowellStAshevilleNC2006PencilandWatercoloronvintagepaper.webp";

//Gallery specific front page images
export const IllustrationGalleryFrontImgs = [
    {
        imgAlt: "AWRoot",
        image: AWRoot,
        width: 200,
        height: 400,
        key: 0,
        title: "Rootbeer Stand",
        // isFeatured: true,
    },
    {
        imgAlt: "AbandonChurch",
        image: AbandonChurch,
        width: 200,
        height: 400,
        key: 1,
        title: "Abandonded Church",
        // isFeatured: true,
    },
    {
        imgAlt: "AmazonFerry",
        image: AmazonFerry,
        width: 200,
        height: 200,
        key: 2,
        title: "Amazon Ferry Boat",
        // isFeatured: true,
    },
    {
        imgAlt: "GrainProcessing",
        image: GrainProcessing,
        width: 200,
        height: 400,
        key: 3,
        title: "Grain Processing",
        // isFeatured: true,
    },
    {
        imgAlt: "GrainStorage",
        image: GrainStorage,
        width: 200,
        height: 200,
        key: 4,
        title: "Grain Storage",
        // isFeatured: true,
    },
    {
        imgAlt: "FarmHouse",
        image: FarmHouse,
        width: 200,
        height: 600,
        key: 5,
        title: "Italianate farmhouse",
        isFeatured: true,
    },
    {
        imgAlt: "TownHouse",
        image: TownHouse,
        width: 200,
        height: 400,
        key: 6,
        title: "Italianate townhouse",
        // isFeatured: true,
    },
    {
        imgAlt: "JacksonBldg",
        image: JacksonBldg,
        width: 200,
        height: 400,
        key: 7,
        title: "Jackson Bldg Asheville",
        // isFeatured: true,
    },
    {
        imgAlt: "OfficeBldg",
        image: OfficeBldg,
        width: 200,
        height: 200,
        key: 8,
        title: "Office Building Asheville",
        isFeatured: true,
    },
    {
        imgAlt: "Tram",
        image: Tram,
        width: 200,
        height: 400,
        key: 9,
        title: "Parking lot tram Dollywood",
        // isFeatured: true,
    },
    {
        imgAlt: "Viaduct",
        image: Viaduct,
        width: 200,
        height: 200,
        key: 10,
        title: "Viaduct Asheville",
        // isFeatured: true,
    },
];

// Galleries in order
export const AWRootGallery = [
    {
        src: AWRoot,
        key: 0,
        alt: "AWRoot",
        caption: "A&W Rootbeer Stand Huntington IN 2005 Pencil and Coffee on vintage paper"
    },
];
export const AbandonChurchGallery = [
    {
        src: AbandonChurch,
        key: 0,
        alt: "AbandonChurch",
        caption: "Abandonded Church Marshall NC 2012 Pencil on vintage paper"
    },
];
export const AmazonFerryGallery = [
    {
        src: AmazonFerry,
        key: 0,
        alt: "AmazonFerry",
        caption: "Amazon Ferry Boat 2006 Pencil and Coffee on vintage paper"
    },
];
export const GrainProcessingGallery = [
    {
        src: GrainProcessing,
        key: 0,
        alt: "GrainProcessing",
        caption: "Grain Processing Hendersonville NC 2006 Pencil and Coffee on vintage paper"
    },
];
export const GrainStorageGallery = [
    {
        src: GrainStorage,
        key: 0,
        alt: "GrainStorage",
        caption: "Grain Storage Grabill IN 2005 Pencil and Coffee on vintage paper"
    },
];
export const FarmHouseGallery = [
    {
        src: FarmHouse,
        key: 0,
        alt: "FarmHouse",
        caption: "Italianate farmhouse Decatur IN 2005 Pencil and Coffee on vintage paper"
    },
];
export const TownHouseGallery = [
    {
        src: TownHouse,
        key: 0,
        alt: "TownHouse",
        caption: "Italianate townhouse Covington KY 2005 Pencil and Coffee on vintage paper"
    },
];
export const JacksonBldgGallery = [
    {
        src: JacksonBldg,
        key: 0,
        alt: "JacksonBldg",
        caption: "Jackson Bldg Asheville NC 2006 Pencil and Water color on illustration board"
    },
];
export const OfficeBldgGallery = [
    {
        src: OfficeBldg,
        key: 0,
        alt: "OfficeBldg",
        caption: "Office Building Lexington College St Asheville NC 2006 Pencil and Coffee on vintage paper"
    },
];
export const TramGallery = [
    {
        src: Tram,
        key: 0,
        alt: "Tram",
        caption: "Parking lot tram Dollywood Pigeon Forge TN 2006 Gouache on vintage notecard"
    },
];
export const ViaductGallery = [
    {
        src: Viaduct,
        key: 0,
        alt: "Viaduct",
        caption: "Viaduct McDowell St Asheville NC 2006 Pencil and Water color on vintage paper"
    },
];
