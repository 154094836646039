/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  ZoomControlOptions
} from "react-google-maps";
// @material-ui/core components
import emailjs from 'emailjs-com'
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import ReCAPTCHA from 'react-google-recaptcha';
import SimpleSnackbar from 'components/SimpleSnackbar';

// styles
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={13.5}
      defaultCenter={{ lat: 35.613113, lng: -82.575190 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        zoomControlOptions: { position: 8 },
        streetViewControlOptions: { position: 4 },
        mapTypeControlOptions: { style: 2 },
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 35.613113, lng: -82.575190 }} />
    </GoogleMap>
  ))
);

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  function sendEmail(e) {
    e.preventDefault();
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    emailjs.sendForm('service_qx2pkcf', 'template_qzavqwh', e.target, 'user_hTrUZUwiMEaQwChDAnScu')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  function snackbar() {
    if (grecaptcha && grecaptcha.getResponse().length !== 0) {
      console.log("it work");
      setSnackbarType("success")
      setSnackbarMessage("Message sent successfully")
      setShowSnackbar(true);
    }
    else {
      console.log("it didnt work");
      setSnackbarMessage("Message not sent - please complete the reCAPTCHA")
      setShowSnackbar(true);
    }
  }
  const recaptchaRef = React.createRef();

  const classes = useStyles();

  return (
    <div>
      {showSnackbar ?
        <SimpleSnackbar type={snackbarType} message={snackbarMessage} /> : null}
      <div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBsDm-or76NjAXT-OtmStVJbiYDh4_t3O8"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "5px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title} style={{ textAlign: "center", color: "#9cc0b9", borderStyle: "none none solid none", borderColor: "#3C4858" }}>
              Send a message
            </h2>
            <GridContainer style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              <GridItem lg={12} md={12} sm={12}>
                <p style={{ textAlign: "center", color: "#f7f6f4", fontSize: "large" }}>
                  You can contact me with anything related to my services. I
                  {"'"}ll get in touch with you as soon as possible.
                  <br />
                  <br />
                </p>
                <form onSubmit={sendEmail}>
                  <CustomInput
                    labelText="Your Name"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "name"
                    }}
                  />
                  <CustomInput
                    labelText="Email address"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "email"
                    }}
                  />
                  <CustomInput
                    labelText="Phone"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "phone"
                    }}
                  />
                  <CustomInput
                    labelText="Your message"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6,
                      name: "message"
                    }}
                  />
                  <div className={classes.bottom}>
                    <div style={{ width: "304px", margin: "auto", paddingBottom: "10px", paddingTop: "10px" }}>
                      <ReCAPTCHA
                        // theme="dark"
                        // ref={recaptchaRef}
                        size="normal"
                        sitekey="6LfW9vMZAAAAAObOUmDvBE4VUw0ACJpQLGvIt-VW"
                      />
                    </div>
                    <Button color="primary" round type="submit" onClick={snackbar}>
                      Send
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={6} sm={6} xs={12} className={classes.mlAuto}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <InfoArea
                  className={classes.info}
                  title="Stop by"
                  description={
                    <p style={{ color: "#f7f6f4" }}>
                      821 Riverside Dr, 
                      <br /> Suite 181, 
                      <br /> Asheville, NC 28801 {" "}
                      <br /> USA
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
              </GridItem>
              <GridItem md={6} sm={6} xs={12} className={classes.mlAuto}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <InfoArea
                  className={classes.info}
                  title="Call"
                  description={
                    <p style={{ color: "#f7f6f4" }}>
                      Scott Allred 
                      <br /> 828-337-0477 
                      <br /> Mon - Fri,
                      <br /> 8:00am - 5:00pm
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}