//Real
import OrangePeel2010 from "assets/img/graphicDesign/OrangePeelposterAshevilleNC2010.webp";
import OrangePeel2009 from "assets/img/graphicDesign/OrangePeeltshirtAshevilleNC2009.webp";
import SummerCampFest from "assets/img/graphicDesign/SummercampFestival2012SouvenirBandanaHanddrawn2colorscreenprint.webp";
import YonderMountain2010Tour from "assets/img/graphicDesign/YonderMountainStringBandCabinFeverTour2010Handdrawn4colorscreenprint.webp";
import TriumphSixPack from "assets/img/graphicDesign/TriumphSixPackrallyposter2011cutpaperoriginal4colorscreenprint.webp";
//Folders
import YonderMountain2012Tour1 from "assets/img/graphicDesign/YonderMountainStringBandTour2012/YonderMountainStringBandCabinFevrTour2012handdrawn3colorscreenprint.webp";
import YonderMountain2012Tour2 from "assets/img/graphicDesign/YonderMountainStringBandTour2012/YonderMountainStringBandCabinFevrTour2012posterdesigninspiration.webp";
//
import YonderMountain2011Tour1 from "assets/img/graphicDesign/YonderMountainStringBandTour2011/YonderMountainStringBandFallTour2011handdrawn4colorscreenprint.webp";
import YonderMountain2011Tour2 from "assets/img/graphicDesign/YonderMountainStringBandTour2011/YonderMountainStringBandWinterTour2011Handdrawn3colorscreenprint.webp";
//
import YonderMountain2009Tour1 from "assets/img/graphicDesign/YonderMountainStringBandTour2009/YonderMountainStringBandKinfolkCelebrationKeychainmerch2009.webp";
import YonderMountain2009Tour2 from "assets/img/graphicDesign/YonderMountainStringBandTour2009/YonderMountainStringBandKinfolkCelebrationTshirtandticketart2009Handdrawn2colorscreenprint.webp";
//
import YonderMountain2013Tour1 from "assets/img/graphicDesign/YonderMountainStringBandPageantTheater2013/YonderMountainStringBandPageantTheater3-8-2013souvenirpennantHanddrawn3colorscreenprint.webp";
import YonderMountain2013Tour2 from "assets/img/graphicDesign/YonderMountainStringBandPageantTheater2013/YonderMountainStringBandPageantTheater3-9-2013souvenirpennantHanddrawn3colorscreenprint.webp";
//
import TomGodleskiFresh1 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/01FreshPreservesinitialnotes.webp";
import TomGodleskiFresh2 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/02FreshPreservesfinishedlabel.webp";
import TomGodleskiFresh3 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/03FreshPreservesoutersleeve.webp";
import TomGodleskiFresh4 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/04FreshPreservesinnersleeve.webp";
import TomGodleskiFresh5 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/05FreshPreservesphototakenforbackcover.webp";
import TomGodleskiFresh6 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/06FreshPreservesfinishedbackcover.webp";
import TomGodleskiFresh7 from "assets/img/graphicDesign/Tom Godleski Fresh Preserves CD/07FreshPreservesfinishedjarforcover-THUMBNAIL.webp";
//
import RicHickey1 from "assets/img/graphicDesign/Ric Hickey Outsider Looking Out/RicHickeydiscart.webp";
import RicHickey2 from "assets/img/graphicDesign/Ric Hickey Outsider Looking Out/RicHickeyinitialsketch.webp";
import RicHickey3 from "assets/img/graphicDesign/Ric Hickey Outsider Looking Out/RicHickeyinside.webp";
import RicHickey4 from "assets/img/graphicDesign/Ric Hickey Outsider Looking Out/RicHickeyoutsidecover.webp";
//
import LefOverSalmon1 from "assets/img/graphicDesign/LefoverSalmonReunionTour/LefoverSalmonreuniontour4pcset.webp";
import LefOverSalmon2 from "assets/img/graphicDesign/LefoverSalmonReunionTour/LeftoverSalmonreuniontourBirchmereTheater9-21-2011Handdrawn4colorscreenprint.webp";
import LefOverSalmon3 from "assets/img/graphicDesign/LefoverSalmonReunionTour/LeftoverSalmonreuniontourBrooklynBowl9-23-2001Handdrawn4colorscreenprint.webp";
import LefOverSalmon4 from "assets/img/graphicDesign/LefoverSalmonReunionTour/LeftoverSalmonreuniontourRecherTheater9-20-2011Handdrawn4colorscreenprint.webp";
import LefOverSalmon5 from "assets/img/graphicDesign/LefoverSalmonReunionTour/LeftoverSalmonreuniontourWorldCafeLive9-22-2011Handdrawn4colorscreenprint.webp";
//
import Devotchka1 from "assets/img/graphicDesign/Devotchka/DevotchkaElephantClubFallTourflyer2011Handlettered2colorscreenprint.webp";
import Devotchka2 from "assets/img/graphicDesign/Devotchka/DevotchkaElephantClubFallTourposter2011Digitalprint.webp";
//
import BuncombeTurnpike1 from "assets/img/graphicDesign/Buncombe Turnpike Ditch Diggin Blues CD and merch/BuncombeTurnpikeDitchDigginBluesCDpackageinside.webp";
import BuncombeTurnpike2 from "assets/img/graphicDesign/Buncombe Turnpike Ditch Diggin Blues CD and merch/BuncombeTurnpikeDitchDigginBluesCDpackageoutsidecover.webp";
import BuncombeTurnpike3 from "assets/img/graphicDesign/Buncombe Turnpike Ditch Diggin Blues CD and merch/BuncombeTurnpikeDitchDigginBluesdisc.webp";
import BuncombeTurnpike4 from "assets/img/graphicDesign/Buncombe Turnpike Ditch Diggin Blues CD and merch/BuncombeTurnpikeDitchDigginBluesmerch.webp";
import BuncombeTurnpike5 from "assets/img/graphicDesign/Buncombe Turnpike Ditch Diggin Blues CD and merch/BuncombeTurnpikeDitchDigginBluesposterblank.webp";
//
import BlueWheelDrive1 from "assets/img/graphicDesign/Blue Wheel Drive Hard Driving Bluegrass CD and merch/BlueWheelDrivediscpackage.webp";
import BlueWheelDrive2 from "assets/img/graphicDesign/Blue Wheel Drive Hard Driving Bluegrass CD and merch/BlueWheelDrivetshirt&sticker.webp";
import BlueWheelDrive3 from "assets/img/graphicDesign/Blue Wheel Drive Hard Driving Bluegrass CD and merch/BWDdisc.webp";
import BlueWheelDrive4 from "assets/img/graphicDesign/Blue Wheel Drive Hard Driving Bluegrass CD and merch/CDfrontcoversmflat.webp";
import BlueWheelDrive5 from "assets/img/graphicDesign/Blue Wheel Drive Hard Driving Bluegrass CD and merch/insidedoorfinalsm.webp";


//Gallery specific front page images
export const GraphicDesignGalleryFrontImgs = [
    {
        imgAlt: "Orange Peel Poster 2010",
        image: OrangePeel2010,
        width: 200,
        height: 400,
        key: 0,
        title: "Orange Peel Poster 2010",
        // isFeatured: true,
    },
    {
        imgAlt: "Orange Peel Poster 2009",
        image: OrangePeel2009,
        width: 200,
        height: 400,
        key: 1,
        title: "Orange Peel Poster 2009",
        // isFeatured: true,
    },
    {
        imgAlt: "Summer Camp Festival Bandana 2012",
        image: SummerCampFest,
        width: 200,
        height: 400,
        key: 2,
        title: "Summer Camp Festival Bandana 2012",
        // isFeatured: true,
    },
    {
        imgAlt: "Triumph Six Pack Poster",
        image: TriumphSixPack,
        width: 200,
        height: 200,
        key: 3,
        title: "Triumph Six Pack Poster",
        // isFeatured: true,
    },
    {
        imgAlt: "Yonder Mountain 2010 Tour",
        image: YonderMountain2010Tour,
        width: 200,
        height: 200,
        key: 4,
        title: "Yonder Mountain 2010 Tour Poster",
        isFeatured: true,
    },
    {
        imgAlt: "Yonder Mountain 2012 Tour",
        image: YonderMountain2012Tour1,
        width: 200,
        height: 300,
        key: 5,
        title: "Yonder Mountain 2012 Tour",
        isFeatured: true,
    },
    {
        imgAlt: "Yonder Mountain 2011 Tour",
        image: YonderMountain2011Tour1,
        width: 200,
        height: 400,
        key: 6,
        title: "Yonder Mountain 2011 Tour Posters",
        // isFeatured: true,
    },
    {
        imgAlt: "Yonder Mountain 2009 Tour Mechandise",
        image: YonderMountain2009Tour1,
        width: 200,
        height: 400,
        key: 7,
        title: "Yonder Mountain 2009 Tour Mechandise",
        // isFeatured: true,
    },
    {
        imgAlt: "Yonder Mountain String Band Pageant Theater 3-8-2013 souvenir pennant",
        image: YonderMountain2013Tour1,
        width: 200,
        height: 400,
        key: 8,
        title: "Yonder Mountain String Band Pageant Theater",
        // isFeatured: true,
    },
    {
        imgAlt: "Tom Godleski - Fresh Preserves",
        image: TomGodleskiFresh6,
        width: 200,
        height: 400,
        key: 9,
        title: "Tom Godleski - Fresh Preserves",
        // isFeatured: true,
    },
    {
        imgAlt: "Ric Hickey - Insider Looking Out 2020 CD packaging",
        image: RicHickey4,
        width: 200,
        height: 200,
        key: 10,
        title: "Ric Hickey - Insider Looking Out CD packaging",
        isFeatured: true,
    },
    {
        imgAlt: "Lefover Salmon - reunion tour 4 piece set",
        image: LefOverSalmon1,
        width: 200,
        height: 500,
        key: 11,
        title: "Lefover Salmon - reunion tour",
        // isFeatured: true,
    },
    {
        imgAlt: "Devotchka Elephant Club Fall Tour",
        image: Devotchka1,
        width: 200,
        height: 200,
        key: 12,
        title: "Devotchka Elephant Club Fall Tour",
        // isFeatured: true,
    },
    {
        imgAlt: "Buncombe Turnpike Ditch Diggin' Blues 2007",
        image: BuncombeTurnpike2,
        width: 200,
        height: 200,
        key: 13,
        title: "Buncombe Turnpike Ditch Diggin' Blues",
        // isFeatured: true,
    },
    {
        imgAlt: "Blue Wheel Drive CD & Merch packaging 2015",
        image: BlueWheelDrive1,
        width: 200,
        height: 600,
        key: 14,
        title: "Blue Wheel Drive CD & Merch packaging",
        // isFeatured: true,
    },
];

// Galleries in order
export const OrangePeel2010Gallery = [
    {
        src: OrangePeel2010,
        key: 0,
        alt: "Orange Peel Poster 2010",
        caption: "Orange Peel Poster 2010"
    },
];
export const OrangePeel2009Gallery = [
    {
        src: OrangePeel2009,
        key: 0,
        alt: "Orange Peel Poster 2009",
        caption: "Orange Peel Poster 2009"
    },
];
export const SummerCampGallery = [
    {
        src: SummerCampFest,
        key: 0,
        alt: "Summer Camp Festival Bandana 2012",
        caption: "Summer Camp Festival Bandana 2012. Hand Drawn 2 color screen print."
    },
];
export const TriumphGallery = [
    {
        src: TriumphSixPack,
        key: 0,
        alt: "Triumph Six Pack Poster",
        caption: "Triumph Six Pack Poster 2011. Cut paper original, 4 color screen print."
    },
];
export const YonderMountain2010Gallery = [
    {
        src: YonderMountain2010Tour,
        key: 0,
        alt: "Yonder Mountain 2010 Tour",
        caption: "Yonder Mountain String Band Cabin Fever Tour 2010. Hand drawn, 4 color screen print."
    },
];
export const YonderMountain2012Gallery = [
    {
        src: YonderMountain2012Tour1,
        key: 0,
        alt: "Yonder Mountain 2012 Tour",
        caption: "Yonder Mountain String Band Cabin Fevr Tour 2012 hand drawn 3 color screen print."
    },
    {
        src: YonderMountain2012Tour2,
        key: 1,
        alt: "Yonder Mountain 2012 Tour",
        caption: "Yonder Mountain String Band Cabin Fevr Tour 2012 poster design inspiration"
    },
];
export const YonderMountain2011Gallery = [
    {
        src: YonderMountain2011Tour1,
        key: 0,
        alt: "Yonder Mountain 2011 Tour",
        caption: "Yonder Mountain String Band Fall Tour 2011 hand drawn 4 color screen print."
    },
    {
        src: YonderMountain2011Tour2,
        key: 1,
        alt: "Yonder Mountain 2011 Tour",
        caption: "Yonder Mountain String Band Winter Tour 2011 Hand drawn 3 color screen print."
    },
];
export const YonderMountain2009Gallery = [
    {
        src: YonderMountain2009Tour1,
        key: 0,
        alt: "Yonder Mountain String Band Kinfolk Celebration Key chain",
        caption: "Yonder Mountain String Band Kinfolk Celebration Key chain merch 2009."
    },
    {
        src: YonderMountain2009Tour2,
        key: 1,
        alt: "Yonder Mountain String Band Kinfolk Celebration Key chain",
        caption: "Yonder Mountain String Band Kinfolk Celebration Tshirt and sticket art 2009 Hand drawn 2 color screen print."
    },
];
export const YonderMountain2013Gallery = [
    {
        src: YonderMountain2013Tour1,
        key: 0,
        alt: "Yonder Mountain String Band Pageant Theater 3-8-2013 souvenir pennant",
        caption: "Yonder Mountain String Band Pageant Theater 3-8-2013 souvenir pennant Hand drawn 3 color screen print."
    },
    {
        src: YonderMountain2013Tour2,
        key: 1,
        alt: "Yonder Mountain String Band Pageant Theater 3-9-2013 souvenir pennant",
        caption: "Yonder Mountain String Band Pageant Theater 3-9-2013 souvenir pennant Hand drawn 3 color screen print."
    },
];
export const FreshPreservesGallery = [
    {
        src: TomGodleskiFresh1,
        key: 0,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "These were a selection of passed down mountain stories and songs. Working with the title, I designed a canning jar label and an old photo album concept Hand drawn and hand lettered and compositted in Photoshop"
    },
    {
        src: TomGodleskiFresh2,
        key: 1,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "Tom Godleski - Fresh Preserves finished label"
    },
    {
        src: TomGodleskiFresh3,
        key: 2,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "Tom Godleski - Fresh Preserves outer sleeve"
    },
    {
        src: TomGodleskiFresh4,
        key: 3,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "Tom Godleski - Fresh Preserves inner sleeve"
    },
    {
        src: TomGodleskiFresh5,
        key: 4,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "Tom Godleski - Fresh Preserves photo taken for back cover"
    },
    {
        src: TomGodleskiFresh6,
        key: 5,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "Tom Godleski - Fresh Preserves finished back cover"
    },
    {
        src: TomGodleskiFresh7,
        key: 6,
        alt: "Tom Godleski - Fresh Preserves",
        caption: "Tom Godleski - Fresh Preserves finished jar for cover"
    },
];
export const RicHickeyGallery = [
    {
        src: RicHickey1,
        key: 0,
        alt: "Ric Hickey CD",
        caption: "Ric Hickey - Insider Looking Out 2020 CD packaging The only suggestion from Ric was to include an image of the owl that lives in his yard. The music inspired me to design a classic songbook style booklet. Hand drawn and hand lettered cover and Photoshop compositted inside and back cover"
    },
    {
        src: RicHickey2,
        key: 1,
        alt: "Ric Hickey CD",
        caption: "Ric Hickey - Insider Looking Out 2020 CD packaging The only suggestion from Ric was to include an image of the owl that lives in his yard. The music inspired me to design a classic songbook style booklet. Hand drawn and hand lettered cover and Photoshop compositted inside and back cover"
    },
    {
        src: RicHickey3,
        key: 2,
        alt: "Ric Hickey CD",
        caption: "Ric Hickey - Insider Looking Out 2020 CD packaging The only suggestion from Ric was to include an image of the owl that lives in his yard. The music inspired me to design a classic songbook style booklet. Hand drawn and hand lettered cover and Photoshop compositted inside and back cover"
    },
    {
        src: RicHickey4,
        key: 3,
        alt: "Ric Hickey CD",
        caption: "Ric Hickey - Insider Looking Out 2020 CD packaging The only suggestion from Ric was to include an image of the owl that lives in his yard. The music inspired me to design a classic songbook style booklet. Hand drawn and hand lettered cover and Photoshop compositted inside and back cover"
    },
];
export const LefOverGallery = [
    {
        src: LefOverSalmon1,
        key: 0,
        alt: "Lef over Salmon reunion tour 4 piece set",
        caption: "Lef over Salmon reunion tour 4 piece set"
    },
    {
        src: LefOverSalmon2,
        key: 1,
        alt: "LeftoverSalmonreuniontourBirchmereTheater9-21-2011Handdrawn4colorscreenprint",
        caption: "Leftover Salmon reunion tour Birchmere Theater 9-21-2011 Hand drawn 4 color screen print"
    },
    {
        src: LefOverSalmon3,
        key: 2,
        alt: "LeftoverSalmonreuniontourBrooklynBowl9-23-2001Handdrawn4 colorscreenprint",
        caption: "Leftover Salmon reunion tour Brooklyn Bowl 9-23-2001 Hand drawn 4 color screen print"
    },
    {
        src: LefOverSalmon4,
        key: 3,
        alt: "LeftoverSalmonreuniontourRecherTheater9-20-2011Handdrawn4colorscreenprint",
        caption: "Leftover Salmon reunion tour Recher Theater 9-20-2011 Hand drawn 4 color screen print"
    },
    {
        src: LefOverSalmon5,
        key: 4,
        alt: "LeftoverSalmonreuniontourWorldCafeLive9-22-2011Handdrawn4colorscreenprint",
        caption: "Leftover Salmon reunion tour World Cafe Live 9-22-2011 Hand drawn 4 color screen print"
    },
];
export const DevotchkaGallery = [
    {
        src: Devotchka1,
        key: 0,
        alt: "DevotchkaElephantClubFallTourflyer2011Handlettered2colorscreenprint",
        caption: "Devotchka Elephant Club Fall Tour flyer 2011 Hand lettered 2 color screen print"
    },
    {
        src: Devotchka2,
        key: 1,
        alt: "DevotchkaElephantClubFallTourposter2011Digitalprint",
        caption: "Devotchka Elephant Club Fall Tour poster 2011 Digital print"
    },
];
export const BuncombeTurnpikeGallery = [
    {
        src: BuncombeTurnpike1,
        key: 0,
        alt: "Buncombe Turnpike Ditch Diggin' Blues 2007",
        caption: "Buncombe Turnpike Ditch Diggin Blues CD package inside. Hand Lettering and Photoshop composit of multiple images."
    },
    {
        src: BuncombeTurnpike2,
        key: 1,
        alt: "Buncombe Turnpike Ditch Diggin' Blues 2007",
        caption: "Buncombe Turnpike Ditch Diggin Blues CD package outside cover."
    },
    {
        src: BuncombeTurnpike3,
        key: 2,
        alt: "Buncombe Turnpike Ditch Diggin' Blues 2007",
        caption: "Buncombe Turnpike Ditch Diggin Blues disc."
    },
    {
        src: BuncombeTurnpike4,
        key: 3,
        alt: "Buncombe Turnpike Ditch Diggin' Blues 2007",
        caption: "Buncombe Turnpike Ditch Diggin Blues merch."
    },
    {
        src: BuncombeTurnpike5,
        key: 4,
        alt: "Buncombe Turnpike Ditch Diggin' Blues 2007",
        caption: "Buncombe Turnpike Ditch Diggin Blues poster blank."
    },
];
export const BlueWheelDriveGallery = [
    {
        src: BlueWheelDrive1,
        key: 0,
        alt: "Blue Wheel Drive disc package",
        caption: "Blue Wheel Drive disc package"
    },
    {
        src: BlueWheelDrive2,
        key: 1,
        alt: "BlueWheelDrivetshirt&sticker",
        caption: "Blue Wheel Drive tshirt & sticker"
    },
    {
        src: BlueWheelDrive3,
        key: 2,
        alt: "Blue Wheel Drive disc package",
        caption: "BWD Disc. The disc design was composited in Photoshop "
    },
    {
        src: BlueWheelDrive4,
        key: 3,
        alt: "Blue Wheel Drive disc package",
        caption: "CD front cover flat. An old truck theme was requested by the band. I Started with the idea of opening the jewel case mimicking the opening of the truck door. A hand lettered and painted logo was photoshopped onto a photo of a photo of an old pickup truck door."
    },
    {
        src: BlueWheelDrive5,
        key: 4,
        alt: "insidedoorfinalsm",
        caption: "inside door final. The inside (cover) of the door was composited mostly of an old Vokswagen Beetle door, Typesetting with Photoshop"
    },
];
