import React from 'react'
import LbButton from './Sections/LB_Button'
import SimpleReactLightbox from "simple-react-lightbox";
// styles
import "./masonary.css";



export default class MyMasonryItem extends React.Component {

    static getColumnSpanFromProps = ({ isFeatured }, getState) => {
      if (isFeatured) {
        return 2;
      }
      return 1;
    }   

    static getImageFromProps = (getState, props) => {
        const image = props.image;
        return image;
    }
    
    static getKeyFromProps = (getState, props) => {
        const key = props.key;
        return key;
    }

    static getGalleryFromProps = (galleryArray, props) => {
        let gallery = galleryArray[props.key];
        return gallery;
    }

    static getImgAltFromProps = (getState, props) => {
        const imgAlt = props.imgAlt;
        return imgAlt;
    }
    
    static getHeightFromProps = (getState, props, columnSpan, columnGutter) => {
        const IMAGE_HEIGHT = props.height;
        const TITLE_HEIGHT = 25;
        const FOOTER_HEIGHT = 25;
        return IMAGE_HEIGHT + TITLE_HEIGHT + FOOTER_HEIGHT;
    }
    
    render() {
        // Should change this component to functional in the future if possible
        // console.log(this.props.imageKey);
        return (
            <div>
                <SimpleReactLightbox>
                {/* Information is passed from MasonaryView to here */}
                    <LbButton 
                        key={this.props.key}
                        src={this.props.image}
                        frontImageKey={this.props.imageKey}
                        style={this.props.style}
                        title={this.props.title}
                        gallery={this.props.gallery}
                    />
                </SimpleReactLightbox>
            </div>
               
        );
    }
  }